import React, { useRef } from "react";
import {
  LogSearchBtn,
  LogSearchContainer,
  LogSearchText,
  LogSearchTimeWrapper,
  LogSearchTypeWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/LogTab.styles";
import { ReactComponent as IcClock } from "../../../../../../asset/icons/ic_clock.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import TimePicker from "../../../../../../util/timePicker/TimePicker";
import { setOpen } from "../../../../../../store/features/timePikcerSlice";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  setLogType,
  setText,
} from "../../../../../../store/features/logSearchSlice";
interface ILogSearchProps {
  log_type: string;
  text?: string;
}

const LogSearch = ({
  setTime,
}: {
  setTime: React.Dispatch<
    React.SetStateAction<{ start_time: string; end_time: string }>
  >;
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ILogSearchProps>();
  const dispatch = useDispatch();
  const timePickerOpen = useSelector(
    (state: RootState) => state.timePicker.open,
  );
  const startTime = useSelector(
    (state: RootState) => state.timePicker.startTime,
  );
  const endTime = useSelector((state: RootState) => state.timePicker.endTime);
  const ref = useRef<HTMLDivElement>(null);
  const onValid: SubmitHandler<ILogSearchProps> = (data: ILogSearchProps) => {
    dispatch(setText(data.text));
    dispatch(setLogType(data.log_type === t("TYPE") ? "ALL" : data.log_type));

    setTime({
      start_time: startTime.replace(":", ""),
      end_time: endTime.replace(":", ""),
    });
  };

  return (
    <LogSearchContainer onSubmit={handleSubmit(onValid)}>
      <LogSearchTimeWrapper
        ref={ref}
        onClick={() => dispatch(setOpen(!timePickerOpen))}
      >
        <IcClock />
        <label>{startTime}</label>
        <label>~</label>
        <label>{endTime}</label>
      </LogSearchTimeWrapper>
      {timePickerOpen && <TimePicker opener={ref} />}
      <LogSearchTypeWrapper {...register("log_type")}>
        <option>{t("TYPE")}</option>
        <option>INFO</option>
        <option>WARNING</option>
        <option>ERROR</option>
      </LogSearchTypeWrapper>
      <LogSearchText {...register("text")} />
      <LogSearchBtn type="submit">{t("SEARCH")}</LogSearchBtn>
    </LogSearchContainer>
  );
};

export default React.memo(LogSearch);
