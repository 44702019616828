import React, { useEffect, useState } from "react";
import {
  DeviceInfoContainer,
  DeviceInfoNameBtn,
  DeviceInfoNameInput,
  DeviceInfoTable,
  DeviceInfoTableTr,
  DeviceInfoTitleTd,
  DeviceInfoValueTd,
} from "../../../../../css/navibox/naviboxDetail/tabContent/DeviceTab.styles";
import Toast from "../../../../../util/Toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setToastMsg,
  setToastOpen,
} from "../../../../../store/features/toastSlice";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
import { RootState } from "../../../../../store/store";
import Spinner from "../../../../../util/Spinner";

interface IDeviceInfo {
  address: string;
  angle: number;
  constructor: string;
  device_name: string;
  installed_date: string;
  ip: string;
  latitude: number;
  longitude: number;
  manufacturer: string;
  pixel: number;
  product_name: string;
  serial_num: string;
}

const DeviceTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.navibox.id);
  const [info, setInfo] = useState<IDeviceInfo>({
    address: "",
    angle: 0,
    constructor: "",
    device_name: "",
    installed_date: "",
    ip: "",
    latitude: 0,
    longitude: 0,
    manufacturer: "",
    pixel: 0,
    product_name: "",
    serial_num: "",
  });
  const [newName, setNewName] = useState("");
  const toastMsg = useSelector((state: RootState) => state.toast.msg);
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxDeviceInfo"],
    queryFn: () => DataApi.naviboxDeviceInfo(id),
  });

  useEffect(() => {
    if (isSuccess) {
      setInfo(data);
    }
  }, [data]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };
  const handleClickBtn = () => {
    if (newName.length > 0) mutation.mutate({ id, device_name: newName });
  };

  const mutation = useMutation<
    string,
    string,
    { id: string; device_name: string }
  >({
    mutationFn: ({ id, device_name }) =>
      DataApi.naviboxUpdateDeviceName(id, device_name),
    onSuccess: (data) => {
      if (Number(data) < 0) {
        dispatch(setToastMsg(t("SAME_NAME")));
        // setMsg(t("SAME_NAME"));
        setNewName("");
        dispatch(setToastOpen(true));
      } else {
        dispatch(setToastMsg(t("COMPLETE_SAVE")));
        setNewName("");
        dispatch(setToastOpen(true));
      }
    },
  });

  return (
    <DeviceInfoContainer>
      {isSuccess ? (
        <DeviceInfoTable>
          <tbody>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("NAVIBOX_NAME")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>
                {info.device_name}
                {/*<DeviceInfoNameInput*/}
                {/*  value={newName || info.device_name}*/}
                {/*  onChange={handleChange}*/}
                {/*/>*/}
                {/*<DeviceInfoNameBtn*/}
                {/*  onClick={handleClickBtn}*/}
                {/*  disabled={newName.length === 0}*/}
                {/*>*/}
                {/*  {t("SAVE")}*/}
                {/*</DeviceInfoNameBtn>*/}

                {/*<Toast message={toastMsg} top={-18} right={85} />*/}
              </DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("ADDRESS")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.address}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("LATITUDE")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.latitude}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("LONGITUDE")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.longitude}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("SERIAL_NUMBER")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.serial_num}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("IP_ADDRESS")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.ip}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("MANUFACTURER")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.manufacturer}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("MODEL_NAME")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.product_name}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("DIRECTION")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.angle}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("PIXEL")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.pixel}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>
                {t("INSTALLATION_CONTRACTOR")}
              </DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.constructor}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
            <DeviceInfoTableTr>
              <DeviceInfoTitleTd>{t("INSTALLED_DATE")}</DeviceInfoTitleTd>
              <DeviceInfoValueTd>{info.installed_date}</DeviceInfoValueTd>
            </DeviceInfoTableTr>
          </tbody>
        </DeviceInfoTable>
      ) : (
        <Spinner />
      )}
    </DeviceInfoContainer>
  );
};

export default DeviceTab;
