import React, { useEffect, useState } from "react";
import {
  BackButton,
  BackButtonContainer,
  TrafficChartTitleWrapper,
  TrafficRiskBox,
  TrafficRiskChart,
} from "../../../../../css/navibox/naviboxDetail/tabContent/TrafficTab.styles";
import Line from "../../../../../util/chart/Line";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
import { setTrafficCode } from "../../../../../store/features/trafficSearchSlice";
import Spinner from "../../../../../util/Spinner";

const TrafficRiskChartBox = ({
  data,
  type,
  titleKey,
  chartColor,
}: {
  data: any;
  type: string;
  titleKey: string;
  chartColor: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.navibox.id);
  const deviceName = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );

  const param = useSelector((state: RootState) => state.trafficSearch);
  const [chartData, setChartData] = useState<any>([]);
  const [categories, setCategories] = useState([]);
  const [dateChartData, setDateChartData] = useState({ name: "", data: [] });
  const [dateChartTime, setDateChartTime] = useState([]);
  const [isDailyChart, setIsDailyChart] = useState(false);
  const [hasEvent, setHasEvent] = useState(true);

  useEffect(() => {
    setHasEvent(true);
    setChartData([data.data]);
    setCategories(data.date);
    setIsDailyChart(false);
  }, [data]);

  const {
    data: dateData,
    isSuccess,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [id, `${type}DateData`, param],
    queryFn: () => DataApi.naviboxTrafficDateData(id, param),
    enabled: param.traffic_code === type,
  });

  useEffect(() => {
    if (isSuccess && dateData.length > 0) {
      setDateChartTime(
        dateData.map((item: { num: number; time: string }) => item.time),
      );
      setDateChartData({
        name: deviceName,
        data: dateData.map((item: { num: number; time: string }) => item.num),
      });

      setHasEvent(false);
      setIsDailyChart(true);
    }
  }, [dateData]);

  useEffect(() => {
    dateChartData.data.length > 0 && setChartData([dateChartData]);
    dateChartTime.length > 0 && setCategories(dateChartTime);
  }, [dateChartData]);

  const handleBackBtn = () => {
    dispatch(setTrafficCode(""));
    setDateChartData({ name: "", data: [] });
    setDateChartTime([]);
    setIsDailyChart(false);
    setHasEvent(true);
    setChartData([data.data]);
    setCategories(data.date);
  };
  return (
    <TrafficRiskBox>
      <TrafficChartTitleWrapper>
        <label>{t(titleKey)}</label>
        {isDailyChart && (
          <BackButtonContainer onClick={handleBackBtn}>
            <BackButton>&lt;</BackButton>
          </BackButtonContainer>
        )}
      </TrafficChartTitleWrapper>
      <TrafficRiskChart>
        {!isLoading || !isFetching ? (
          <Line
            data={chartData}
            categories={categories}
            height={250}
            showLegend={false}
            type={type}
            hasEvent={hasEvent}
            strokeColor={chartColor}
          />
        ) : (
          <Spinner size={70} />
        )}
      </TrafficRiskChart>
    </TrafficRiskBox>
  );
};

export default TrafficRiskChartBox;
