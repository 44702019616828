import React, { useEffect } from "react";
import {
  ControlStatusContainer,
  ControlStatusWrapper,
  ControlTitle,
} from "../../../../../css/navibox/naviboxDetail/tabContent/ControlTab.styles";
import { ReactComponent as IcCheck } from "../../../../../asset/icons/ic_check.svg";
import { ReactComponent as IcNoCheck } from "../../../../../asset/icons/ic_nocheck.svg";
import { useTranslation } from "react-i18next";
import { DataApi } from "../../../../../api/DataApi";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

const ControlStatus = () => {
  const id = useSelector((state: RootState) => state.navibox.id);
  const { t } = useTranslation();
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxControl", id],
    queryFn: () => DataApi.naviboxControlStatus(id),
  });
  const isConnected = useSelector(
    (state: RootState) => state.navibox.deviceConnect,
  );
  return (
    <ControlStatusContainer $active={isConnected}>
      <ControlTitle>{t("STATUS")}</ControlTitle>
      <ControlStatusWrapper>
        <label>{t("RECORD_STATUS")}</label>
        {isSuccess && isConnected && data.record ? <IcCheck /> : <IcNoCheck />}
      </ControlStatusWrapper>
      <ControlStatusWrapper>
        <label>{t("ALGORITHM_STATUS")}</label>
        {isSuccess && isConnected && data.algorithm ? (
          <IcCheck />
        ) : (
          <IcNoCheck />
        )}
      </ControlStatusWrapper>
      <ControlStatusWrapper>
        <label>{t("PANEL_STATUS")}</label>
        {isSuccess && isConnected && data.panel ? <IcCheck /> : <IcNoCheck />}
      </ControlStatusWrapper>
      <ControlStatusWrapper>
        <label>{t("SPEAKER_CONNECT_STATUS")}</label>
        {isSuccess && isConnected && data.speaker ? <IcCheck /> : <IcNoCheck />}
      </ControlStatusWrapper>
      <ControlStatusWrapper>
        <label>{t("SPEAKER_VOLUME_STATUS")}</label>
        {isSuccess && isConnected && data.speaker_volume ? (
          <IcCheck />
        ) : (
          <IcNoCheck />
        )}
      </ControlStatusWrapper>
    </ControlStatusContainer>
  );
};

export default ControlStatus;
