import styled, { keyframes } from "styled-components";
import { FlexRow } from "../../common/Common.styles";
import colors from "../../global/colors";

export const DetailTabContentContainer = styled.div`
  width: 100%;
  margin: 60px 0;
`;

export const DetailTabContent = styled.div`
  display: none;
  color: white;

  &.tab-active {
    display: flex;
  }
`;
export const HasFileDotWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HasFileDot = styled.div`
  margin-top: 30px;
  border: 3px solid #114ce4;
  border-radius: 3px;
  background-color: #114ce4;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  z-index: 1001;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  animation: ${spin} 2s linear infinite;
`;

export const LoaderBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 1000;
  opacity: 0.8;
`;
