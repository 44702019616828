import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const NaviboxStreamPreview = ({
  is_connected,
  deviceName,
}: {
  is_connected: boolean;
  deviceName: string;
}) => {
  // const webSocket = useRef<WebSocket | null>(null);
  // const canvasRef = useRef<HTMLCanvasElement | null>(null);
  //
  // function loadImageAsync(blob: Blob) {
  //   return new Promise<HTMLImageElement>((resolve, reject) => {
  //     const img = new Image();
  //     img.onload = () => resolve(img);
  //     img.onerror = reject;
  //     img.src = URL.createObjectURL(blob);
  //   });
  // }
  // useEffect(() => {
  //   webSocket.current = new WebSocket(
  //     `wss://thai.bluesignal.ai/ws/video/${deviceId}/0`,
  //   );
  //
  //   webSocket.current.onmessage = async (event: MessageEvent) => {
  //     try {
  //       const img = await loadImageAsync(event.data);
  //       const canvas = canvasRef.current;
  //       if (canvas) {
  //         const ctx = canvas.getContext("2d");
  //         ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
  //         ctx && ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //       }
  //     } catch (error) {
  //       console.error("Image load failed:", error);
  //     }
  //   };
  //
  //   return () => {
  //     webSocket.current?.close();
  //   };
  // }, [deviceId]);

  const videoRef = useRef<HTMLVideoElement>(null);

  // return <canvas ref={canvasRef} width="640" height="480"></canvas>;
  useEffect(() => {
    if (is_connected && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(`https://navibox.bluesignal.ai/hls/${deviceName}-0.m3u8`);
      videoRef.current && hls.attachMedia(videoRef.current);
    }
  }, [deviceName]);
  return <video ref={videoRef} autoPlay={true} muted={true} />;
};

export default NaviboxStreamPreview;
