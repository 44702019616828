import React, { useEffect, useState } from "react";
import { StatusContent } from "../../../../../css/navibox/naviboxDetail/tabContent/StatusTab.styles";
import Temp, { initialStatusTempData } from "./sections/Temp";
import { FlexColumn, FlexRow } from "../../../../../css/common/Common.styles";
import Gpu from "./sections/Gpu";
import Storage from "./sections/Storage";
import Ram from "./sections/Ram";
import Cpu from "./sections/Cpu";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

const StatusTab = () => {
  const id = useSelector((state: RootState) => state.navibox.id);
  const [temp, setTemp] = useState(initialStatusTempData);
  const [gpu, setGpu] = useState(0);
  const [cpu, setCpu] = useState([0, 0, 0, 0, 0, 0]);
  const [storage, setStorage] = useState(0);
  const [ram, setRam] = useState(0);
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxStatus", id],
    queryFn: () => DataApi.naviboxStatus(id),
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (isSuccess) {
      if (data.is_connected) {
        setTemp(data.temperature);
        setGpu(data.usage.gpu);
        setCpu(JSON.parse(data.usage.cpu));
        setStorage(data.usage.storage);
        setRam(data.usage.ram);
      } else {
        setTemp(initialStatusTempData);
        setGpu(0);
        setCpu([0, 0, 0, 0, 0, 0]);
        setStorage(0);
        setRam(0);
      }
    }
  }, [data]);
  return (
    <StatusContent>
      <FlexColumn>
        <Temp data={temp} />
        <FlexRow>
          <Gpu data={gpu} />
          <FlexColumn>
            <Storage data={storage} />
            <Ram data={ram} />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
      <Cpu data={cpu} />
    </StatusContent>
  );
};

export default StatusTab;
