import React, { useEffect, useState } from "react";
import {
  ContainerTitle,
  DashboardContent,
  TextGray,
} from "../css/dashboard/Dashboard.styles";
import { FlexColumn, FlexRow } from "../css/common/Common.styles";
import FileStatus, {
  initialFileStatusData,
} from "../content/dashboard/FileStatus";
import NaviboxStatus, {
  initialNaviboxStatusData,
} from "../content/dashboard/NaviboxStatus";
import AnomalyDetectionStatus from "../content/dashboard/AnomalyDetectionStatus";
import TrafficVolumeSpeed, {
  initialTrafficVolumeSpeedData,
} from "../content/dashboard/trafficChart/TrafficVolumeSpeed";
import TrafficRisk, {
  initialTrafficRiskData,
} from "../content/dashboard/trafficChart/TrafficRisk";
import { TrafficContainer } from "../css/dashboard/TrafficChart.styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../api/DataApi";
import NaviboxOperation from "../content/dashboard/NaviboxOperation";

const Dashboard = () => {
  const { t } = useTranslation();
  const [fileStatusData, setFileStatusData] = useState(initialFileStatusData);
  const [naviboxStatusData, setNaviboxStatusData] = useState(
    initialNaviboxStatusData,
  );
  const [trafficVolumeSpeedData, setTrafficVolumeSpeedData] = useState(
    initialTrafficVolumeSpeedData,
  );
  const [trafficRiskData, setTrafficRiskData] = useState(
    initialTrafficRiskData,
  );
  const [warningsData, setWarningsData] = useState({});
  const { data, isSuccess } = useQuery({
    queryKey: ["dashboard"],
    queryFn: DataApi.dashboard,
  });
  useEffect(() => {
    if (isSuccess) {
      setFileStatusData(data.num_video_image_log);
      setNaviboxStatusData(data.status);
      setTrafficVolumeSpeedData({
        avg_speed_top_3: data.avg_speed_top_3,
        traffic_volume_top3: data.traffic_volume_top3,
      });
      setTrafficRiskData(data.risk_warning_top_3);
      // setWarningsData(data.warnings);
    }
  }, [data]);
  return (
    <DashboardContent>
      <FlexRow>
        {/* 나비박스 현황 컨테이너 및 내용 */}
        <NaviboxOperation />
      </FlexRow>

      <FlexRow>
        {/* 파일 상태 컨테이너 및 내용 */}
        <FileStatus data={fileStatusData} />
        {/* 이상 징후 감지 컨테이너 및 내용 */}
        <AnomalyDetectionStatus data={warningsData} />
      </FlexRow>
    </DashboardContent>

    // <DashboardContent>
    //   <FlexRow>
    //     {/* 파일 상태 컨테이너 및 내용 */}
    //     <FileStatus data={fileStatusData} />
    //     {/* 나비박스 현황 컨테이너 및 내용 */}
    //     <NaviboxStatus data={naviboxStatusData} />
    //   </FlexRow>
    //
    //   <FlexRow>
    //     {/* 이상 징후 감지 컨테이너 및 내용 */}
    //     <AnomalyDetectionStatus data={warningsData} />
    //     <FlexColumn>
    //       {/* 트래픽 컨테이너 및 내용 */}
    //       <TrafficContainer>
    //         <ContainerTitle>
    //           {t("TRAFFIC_TITLE")}
    //           <TextGray> {t("TRAFFIC_SUB_TITLE")}</TextGray>
    //         </ContainerTitle>
    //         <TrafficVolumeSpeed data={trafficVolumeSpeedData} />
    //       </TrafficContainer>
    //       <TrafficRisk data={trafficRiskData} />
    //     </FlexColumn>
    //   </FlexRow>
    // </DashboardContent>
  );
};

export default Dashboard;
