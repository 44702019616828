import React from "react";
import {
  ContainerTitle,
  TextBlue,
  TextGreen,
} from "../../css/dashboard/Dashboard.styles";
import {
  AlgorithmChart,
  BoardChart,
  NaviboxStatusChartBox,
  NaviboxStatusChartContainer,
  NaviboxStatusChartLabelWrapper,
  NaviboxStatusChartTitle,
  NaviboxStatusChartValue,
  NaviboxStatusChartValueWrapper,
  NaviboxStatusChartWrapper,
  NaviboxStatusContainer,
  OperationChart,
  RecordChart,
} from "../../css/dashboard/NaviboxStatus.styles";
import Radial from "../../util/chart/Radial";
import colors from "../../css/global/colors";
import { useTranslation } from "react-i18next";
export interface INaviboxStatusProps {
  data: {
    algorithm: number;
    is_connected: number;
    panel: number;
    percentage_algorithm: number;
    percentage_operating: number;
    percentage_panel: number;
    percentage_record: number;
    record: number;
    total: number;
  };
}

export const initialNaviboxStatusData = {
  algorithm: 0,
  is_connected: 0,
  panel: 0,
  percentage_algorithm: 0,
  percentage_operating: 0,
  percentage_panel: 0,
  percentage_record: 0,
  record: 0,
  total: 0,
};

const NaviboxStatus = ({ data }: INaviboxStatusProps) => {
  const { t } = useTranslation();

  return (
    <NaviboxStatusContainer>
      <ContainerTitle>
        {t("NAVIBOX_STATUS_TITLE")}
        <TextBlue>
          {data.total}
          {t("NUM_UNIT")}
        </TextBlue>
      </ContainerTitle>
      <NaviboxStatusChartContainer>
        <OperationChart>
          <Radial
            data={[data.percentage_operating]}
            color={colors.chart.green}
            height={260}
          />
          <NaviboxStatusChartLabelWrapper>
            <NaviboxStatusChartTitle>
              {t("NAVIBOX_OPERATION")}
            </NaviboxStatusChartTitle>
            <TextGreen>{data.percentage_operating}%</TextGreen>
          </NaviboxStatusChartLabelWrapper>
        </OperationChart>
        <AlgorithmChart>
          <Radial
            data={[data.algorithm]}
            color={colors.chart.green}
            height={260}
          />
          <NaviboxStatusChartLabelWrapper>
            <NaviboxStatusChartTitle>
              {t("NAVIBOX_ALGORITHM")}
            </NaviboxStatusChartTitle>
            <TextGreen>{data.algorithm}%</TextGreen>
          </NaviboxStatusChartLabelWrapper>
        </AlgorithmChart>
        <BoardChart>
          <Radial
            data={[data.percentage_panel]}
            color={colors.chart.green}
            height={260}
          />
          <NaviboxStatusChartLabelWrapper>
            <NaviboxStatusChartTitle>
              {t("NAVIBOX_BOARD")}
            </NaviboxStatusChartTitle>
            <TextGreen>{data.percentage_panel}%</TextGreen>
          </NaviboxStatusChartLabelWrapper>
        </BoardChart>
        <RecordChart>
          <Radial
            data={[data.record]}
            color={colors.chart.green}
            height={260}
          />

          <NaviboxStatusChartLabelWrapper>
            <NaviboxStatusChartTitle>
              {t("NAVIBOX_RECORD")}
            </NaviboxStatusChartTitle>
            <TextGreen>{data.record}%</TextGreen>
          </NaviboxStatusChartLabelWrapper>
        </RecordChart>
      </NaviboxStatusChartContainer>
    </NaviboxStatusContainer>
    // <NaviboxStatusContainer>
    //   <ContainerTitle>
    //     {t("NAVIBOX_STATUS_TITLE")}
    //     <TextBlue>
    //       {data.total}
    //       {t("NUM_UNIT")}
    //     </TextBlue>
    //   </ContainerTitle>
    //   <NaviboxStatusChartContainer>
    //     <OperationChart>
    //       <Radial
    //         data={[data.percentage_operating]}
    //         color={colors.chart.green}
    //         height={260}
    //       />
    //       <NaviboxStatusChartLabelWrapper>
    //         <NaviboxStatusChartTitle>
    //           {t("NAVIBOX_OPERATION")}
    //         </NaviboxStatusChartTitle>
    //         <TextGreen>{data.percentage_operating}%</TextGreen>
    //       </NaviboxStatusChartLabelWrapper>
    //     </OperationChart>
    //     <AlgorithmChart>
    //       <Radial
    //         data={[data.algorithm]}
    //         color={colors.chart.green}
    //         height={260}
    //       />
    //       <NaviboxStatusChartLabelWrapper>
    //         <NaviboxStatusChartTitle>
    //           {t("NAVIBOX_ALGORITHM")}
    //         </NaviboxStatusChartTitle>
    //         <TextGreen>{data.algorithm}%</TextGreen>
    //       </NaviboxStatusChartLabelWrapper>
    //     </AlgorithmChart>
    //     <BoardChart>
    //       <Radial
    //         data={[data.percentage_panel]}
    //         color={colors.chart.green}
    //         height={260}
    //       />
    //       <NaviboxStatusChartLabelWrapper>
    //         <NaviboxStatusChartTitle>
    //           {t("NAVIBOX_BOARD")}
    //         </NaviboxStatusChartTitle>
    //         <TextGreen>{data.percentage_panel}%</TextGreen>
    //       </NaviboxStatusChartLabelWrapper>
    //     </BoardChart>
    //     <RecordChart>
    //       <Radial
    //         data={[data.record]}
    //         color={colors.chart.green}
    //         height={260}
    //       />
    //
    //       <NaviboxStatusChartLabelWrapper>
    //         <NaviboxStatusChartTitle>
    //           {t("NAVIBOX_RECORD")}
    //         </NaviboxStatusChartTitle>
    //         <TextGreen>{data.record}%</TextGreen>
    //       </NaviboxStatusChartLabelWrapper>
    //     </RecordChart>
    //   </NaviboxStatusChartContainer>
    // </NaviboxStatusContainer>
  );
};

export default NaviboxStatus;
