import React from "react";
import {
  StatusTempCanvasText,
  StatusTempCanvasTitle,
  StatusTempCanvasValue,
  StatusChartWrapper,
  StatusTempCanvas,
  StatusTempContainer,
  StatusTempRow,
  StatusTitleWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/StatusTab.styles";
import Radial from "../../../../../../util/chart/Radial";
import colors from "../../../../../../css/global/colors";
import { initialNavibosData } from "../../../../naviboxList/ChartBox";
import { getTempColor } from "../../../../../../util/hook";

export interface IStatusTempProps {
  data: {
    CPU: number;
    CV0: number;
    CV1: number;
    CV2: number;
    GPU: number;
    SOC0: number;
    SOC1: number;
    SOC2: number;
    iwlwifi: number;
    tj: number;
    [key: string]: number;
  };
}

export const initialStatusTempData = {
  CPU: 0,
  CV0: 0,
  CV1: 0,
  CV2: 0,
  GPU: 0,
  SOC0: 0,
  SOC1: 0,
  SOC2: 0,
  iwlwifi: 0,
  tj: 0,
};
const Temp = ({ data }: IStatusTempProps) => {
  function extractData(
    data: IStatusTempProps["data"],
  ): Partial<IStatusTempProps["data"]> {
    const keysToExtract = ["CPU", "GPU", "tj", "SOC0", "SOC1", "SOC2"];
    return keysToExtract.reduce(
      (result, key) => {
        if (key in data) {
          result[key] = data[key];
        }
        return result;
      },
      {} as Partial<IStatusTempProps["data"]>,
    );
  }

  const formattedData = Object.entries(extractData(data));

  return (
    <StatusTempContainer>
      <StatusTitleWrapper>
        <label>TEMP</label>
      </StatusTitleWrapper>
      <StatusTempRow>
        {formattedData.map(([type, value], index) =>
          index < 3 ? (
            <StatusChartWrapper key={index}>
              <StatusTempCanvas>
                <Radial
                  data={[value || 0]}
                  color={getTempColor(value || 0)}
                  valueShow={false}
                  height={240}
                />
              </StatusTempCanvas>
              <StatusTempCanvasText>
                <StatusTempCanvasTitle>{type}</StatusTempCanvasTitle>
                <StatusTempCanvasValue>
                  {Math.round((value || 0) * 10) / 10 + "°C"}
                </StatusTempCanvasValue>
              </StatusTempCanvasText>
            </StatusChartWrapper>
          ) : null,
        )}
      </StatusTempRow>
      <StatusTempRow>
        {formattedData.map(([type, value], index) =>
          index > 2 ? (
            <StatusChartWrapper key={index}>
              <StatusTempCanvas>
                <Radial
                  data={[value || 0]}
                  color={getTempColor(value || 0)}
                  valueShow={false}
                  height={240}
                />
              </StatusTempCanvas>
              <StatusTempCanvasText>
                <StatusTempCanvasTitle>{type}</StatusTempCanvasTitle>
                <StatusTempCanvasValue>
                  {Math.round((value || 0) * 10) / 10 + "°C"}
                </StatusTempCanvasValue>
              </StatusTempCanvasText>
            </StatusChartWrapper>
          ) : null,
        )}
      </StatusTempRow>
    </StatusTempContainer>
  );
};

export default Temp;
