import React from "react";
import ApexChart from "react-apexcharts";
import { ChartProps } from "../../interface/Chart";
import colors from "../../css/global/colors";
import "../../css/util/Tooltip.css";
import {
  setDate,
  setTrafficCode,
} from "../../store/features/trafficSearchSlice";
import { useDispatch } from "react-redux";
const Line = ({
  data,
  title,
  categories,
  height,
  showLegend = true,
  strokeColor,
  hasEvent = true,
  type = "",
}: ChartProps) => {
  const dispatch = useDispatch();

  return (
    <ApexChart
      type="line"
      series={data}
      height={height}
      options={{
        chart: {
          toolbar: {
            show: false,
            tools: {
              zoom: true,
              zoomin: true,
              zoomout: true,
            },
            autoSelected: "zoom",
          },

          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 1,
              },
            },
          },

          foreColor: colors.text.secondary,
          background: "transparent",
          events: {
            markerClick(
              event,
              chartContext,
              { seriesIndex, dataPointIndex, config },
            ) {
              if (hasEvent) {
                const clickedDate = categories[dataPointIndex].replaceAll(
                  "-",
                  "",
                );
                setTimeout(() => {
                  dispatch(setDate(clickedDate));
                  dispatch(setTrafficCode(type));
                }, 0);
              }
            },
          },
        },
        legend: {
          show: showLegend,
          showForSingleSeries: true,
          showForNullSeries: false,
          showForZeroSeries: false,
          customLegendItems: data.map((data) => data.name),
          position: "top",
          horizontalAlign: "right",
          fontSize: "12px",
          fontFamily: "SUIT",
          fontWeight: 500,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        theme: {
          mode: "dark",
        },
        title: {
          text: title,
          offsetY: 15,
          style: {
            fontSize: "14px",
            fontFamily: "SUIT",
            fontWeight: 600,
            color: "#d7d7d7",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: colors.chart.background,
        },
        stroke: {
          width: 4,
          curve: "straight",
        },
        markers: {
          size: 5,
          strokeColors: "#191b25",
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            align: "right",
            style: {
              fontSize: "10",
              fontFamily: "SUIT",
              fontWeight: 500,
            },
            formatter(val: number, opts?: any): string | string[] {
              return val.toLocaleString();
            },
          },
        },
        xaxis: {
          categories: categories,
          tickPlacement: "between",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: { enabled: false },
          labels: {
            show: true,
            rotateAlways: false,
            rotate: -45,
            hideOverlappingLabels: true,
            style: {
              fontSize: "12",
              fontFamily: "SUIT",
              fontWeight: 500,
            },
          },
        },
        colors: [
          strokeColor ? strokeColor : colors.chart.blue,
          colors.chart.sky,
          colors.chart.purple,
        ],
        fill: {
          opacity: 1,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let html =
              (data.length >= 3
                ? "<div class='tooltip-container'>"
                : "<div class='tooltip-container-sm'>") +
              "<div class='tooltip-title'>" +
              w.globals.labels[dataPointIndex] +
              "</div>" +
              "<hr class='tooltip-title-line'/>" +
              "<div class='tooltip-box'>";

            data.forEach((item, index) => {
              html +=
                "<div class='tooltip-label-wrapper'>" +
                "<span class='tooltip-legend" +
                (index + 1) +
                "'>" +
                item.name +
                "</span>" +
                "<span class='tooltip-value'>" +
                series[index][dataPointIndex].toLocaleString() +
                "</span>" +
                "</div>";
            });

            // 컨테이너 닫기
            html += "</div></div>";
            return html;
          },
        },
      }}
    />
  );
};

export default Line;
