import React from "react";

import {
  FlexRowDivision,
  Video16,
  VideoContainerDivision,
} from "../css/navibox/naviboxDetail/tabContent/VideoTab.styles";

const Division16 = () => {
  return (
    <VideoContainerDivision>
      <FlexRowDivision>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
        <Video16>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video16>
      </FlexRowDivision>
    </VideoContainerDivision>
  );
};

export default Division16;
