import axios from "axios";
import { INaviBoxLogParams } from "../content/navibox/naviboxDetail/tabContent/logTab/logContent/LogContent";
import { IUpdateForm } from "../page/Account";
import { IUpdatePassword } from "../content/account/PasswordModal";
import { IResignForm } from "../content/account/ResignModal";
import { INaviboxControl } from "../content/navibox/naviboxDetail/tabContent/controlTab/ControlBtns";

export const DataApi = {
  dashboard: async () => {
    const response = await axios.get("/dashboard/data");
    return response.data;
  },
  naviboxList: async () => {
    const response = await axios.get("/navibox/list");
    return response.data;
  },

  naviboxCapture: async (device_name: string, camera?: number) => {
    const response = await axios.post(
      "/direct-method",
      {
        device_name,
        method: "capture",
        payload: "",
        button_name: "Capture Camera",
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return response.data;
  },

  naviboxStatus: async (id: string) => {
    const response = await axios.get(`/navibox/detail/${id}/status`);
    return response.data;
  },

  naviboxControl: async (params: INaviboxControl) => {
    const response = await axios.post(
      `/direct-method`,
      {
        device_name: params.device_name,
        method: params.method,
        payload: params.payload,
        button_name: params.button_name,
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    return "ok";
  },

  naviboxControlStatus: async (id: string) => {
    const response = await axios.get(`/navibox/detail/${id}/control`);
    return response.data;
  },

  naviboxTrafficData: async (id: string, period: string, offset: number) => {
    const response = await axios.get(
      `/navibox/detail/${id}/traffic-stats?period=${period}&offset=${offset}`,
    );

    return response.data;
  },
  naviboxTrafficDateData: async (
    id: string,
    params: {
      date: string;
      traffic_code: string;
      offset: number;
    },
  ) => {
    const { date, traffic_code, offset } = params;
    const response = await axios.get(
      `/navibox/detail/${id}/day-traffic-stats`,
      {
        params: {
          date,
          traffic_code,
          offset,
        },
      },
    );

    return response.data;
  },
  naviboxVideoEventDates: async (id: string, date: string) => {
    const response = await axios.get(`/navibox/detail/${id}/exists`, {
      params: { menu: "video", date },
    });
    return response.data;
  },
  naviboxVideo: async (deviceName: string, date: string, offset: number) => {
    const response = await axios.get(
      `/video/${deviceName}/${date}?offset=${offset}`,
    );
    return response.data;
  },
  naviboxImageEvnetDates: async (id: string, date: string) => {
    const response = await axios.get(`/navibox/detail/${id}/exists`, {
      params: { menu: "capture", date },
    });
    return response.data;
  },
  naviboxImage: async (
    deviceName: string,
    date: string,
    offset: number,
    camera_num: number,
  ) => {
    const response = await axios.get(
      `/capture/${deviceName}/${date}?offset=${offset}`,
      {
        params: { camera_num: camera_num },
      },
    );
    return response.data;
  },
  naviboxLogEvnetDates: async (id: string, date: string) => {
    const response = await axios.get(`/navibox/detail/${id}/exists`, {
      params: { menu: "log", date },
    });
    return response.data;
  },
  naviboxLog: async (
    deviceName: string,
    date: string,
    params: INaviBoxLogParams,
  ) => {
    const response = await axios.get(`/log/${deviceName}/${date}`, {
      params,
    });
    return response.data;
  },
  naviboxDeviceInfo: async (id: string) => {
    const response = await axios.get(`/navibox/detail/info/${id}`);
    return response.data;
  },
  naviboxUpdateDeviceName: async (id: string, device_name: string) => {
    const response = await axios.put(
      `/navibox/detail/name/${id}`,
      {
        device_name,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    );
    return response.data;
  },
  accountInfo: async () => {
    const response = await axios.get("/user/info");
    return response.data;
  },
  updateAccountInfo: async (param: IUpdateForm) => {
    const { phone_num, start_screen } = param;
    const response = await axios.put(
      `/user/info`,
      {
        phone_num,
        start_screen,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    );
    return response.data;
  },

  updatePassword: async (param: IUpdatePassword) => {
    const { password, new_password } = param;
    const response = await axios.put(
      "/user/password",
      {
        password,
        new_password,
      },
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
    );
    return response.data;
  },

  deleteAccount: async (data: IResignForm) => {
    const response = await axios.delete("/user", {
      data,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return response.data;
  },
};
