import styled, { css } from "styled-components";
import colors from "../../../global/colors";
import { BlueBtn, FlexColumn, FlexRow } from "../../../common/Common.styles";

export const LogContainer = styled.div`
  position: relative;
  width: 1280px;
  height: 628px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
`;

export const LogTimePickerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  z-index: 2;
`;

export const LogTimePicker = styled.div<{ show: boolean }>`
  position: absolute;
  top: 80px;
  left: 28px;
  width: 212px;
  height: 259px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 30px 20px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${colors.box.darkBox};
  z-index: 10;
  visibility: hidden;

  ${(props) =>
    props.show &&
    css`
      visibility: visible;
    `}
`;

export const LogTimePickerItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogTimePickerTitle = styled.div`
  display: flex;
  justify-content: center;
  font-family: SUIT;
  font-size: 12px;
  font-weight: 600;
  color: #5c617a;
  margin-bottom: 10px;
`;

export const LogTimePickerContainer = styled.div`
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LogTimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
`;

export const LogTimePickerTime = styled.div<{ active: boolean }>`
  width: 36px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 600;
  color: #d7d7d7;
  margin: 5px;

  ${(props) =>
    props.active &&
    css`
      background-color: #114ce4;
    `}
`;

export const LogBox = styled(FlexColumn)`
  width: 840px;
  height: 628px;
`;

export const LogSearchContainer = styled.form`
  position: relative;
  width: 800px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  background: ${colors.box.darkGray};
`;

export const LogSearchTimeWrapper = styled.div`
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px;
  background-color: ${colors.box.darkBox};
  cursor: pointer;
  svg {
    margin-right: 20px;
  }
  label {
    font-family: SUIT;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background: none;
    cursor: pointer;
  }
`;

// SVG 스타일은 별도의 컴포넌트로 처리해야 할 수 있습니다.

export const LogSearchTypeWrapper = styled.select`
  width: 190px;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.box.darkBox};
  outline: none;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  option {
    cursor: pointer;
  }
`;

export const LogSearchText = styled.input`
  width: 220px;
  height: 50px;
  padding: 0 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: ${colors.box.darkBox};
  font-family: SUIT;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
`;

export const LogSearchBtn = styled(BlueBtn)`
  width: 90px;
  height: 50px;
`;

export const LogTextContainer = styled.div`
  width: 820px;
  display: flex;
  flex-direction: column;
  background: #1f212c;
  padding: 0 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

export const LogTextScrollBox = styled(FlexColumn)`
  width: 760px;
  height: 468px;
  margin-top: 10px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #1f212c;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 6px;
    background-color: #5c617a;
  }

  &::-webkit-scrollbar-track {
    background-color: #1f212c;
  }
`;

export const LogWrapper = styled(FlexColumn)`
  position: relative;
  width: 720px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  background-color: #262937;
`;

export const LogTitleWrapper = styled(FlexRow)`
  position: relative;
  justify-content: space-between;
  font-family: SUIT;
  font-size: 14px;
  color: #d7d7d7;
`;

export const LogTextWrapper = styled(FlexColumn)`
  margin-top: 10px;
  font-family: SUIT;
  font-size: 14px;
  font-weight: 400;
  color: #e5e5e5;
`;

export const LogNoData = styled.div`
  //background-color: yellow;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #d7d7d7;
    font-family: SUIT;
    font-size: 16px;
  }
`;

export const LogDateContainer = styled.div`
  width: 320px;
  height: 548px;
  position: relative;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: #1f212c;
`;

export const LogCalendarDescription = styled(FlexColumn)`
  position: absolute;
  left: 50px;
  bottom: 40px;
`;

export const LogCalendarDecriptionBox = styled(FlexRow)`
  align-items: center;
  font-family: SUIT;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.text.secondary};
  margin-top: 20px;
`;

export const LogCalendarDescriptionDotWrapper = styled(FlexRow)`
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const LogDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: ${colors.btn.primary};
`;
export const SelectedDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${colors.btn.primary};
`;

export const TodayDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: solid 1px ${colors.chart.green};
`;
