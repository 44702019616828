import styled, { css, keyframes } from "styled-components";
import colors from "../global/colors";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  animation: ${fadeIn} 0.2s ease-out forwards;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  z-index: 15;
  border-radius: 10px;
  background-color: ${colors.box.basic};
`;

export const ModalClose = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: -10px;
  border: none;
  border-radius: 50%;
  font-size: 10px;
  background-color: ${colors.btn.darkBlue};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${colors.btn.darkBlueHover};
  }
`;

export const ModalErrorWrapper = styled.div`
  margin-top: 20px;
  font-family: SUIT;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  color: ${colors.text.error};
`;
