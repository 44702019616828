import React from "react";
import {
  SpinnerContainer,
  SpinnerCore,
  SpinnerOuter,
} from "../css/util/Spinner.styles";

const Spinner = ({
  size = 45,
  bgColor = "#1f212c",
}: {
  size?: number;
  bgColor?: string;
}) => {
  return (
    <SpinnerContainer>
      <SpinnerOuter $size={size}>
        <SpinnerCore $bgColor={bgColor}></SpinnerCore>
      </SpinnerOuter>
    </SpinnerContainer>
  );
};

export default Spinner;
