import React from "react";
import {
  StatusChartWrapper,
  StatusCPUCanvas,
  StatusCPUContainer,
  StatusCPUGrid,
  StatusCPUText,
  StatusCPUTitle,
  StatusCPUValue,
  StatusTitleWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/StatusTab.styles";
import Radial from "../../../../../../util/chart/Radial";
import colors from "../../../../../../css/global/colors";

const Cpu = ({ data }: { data: number[] }) => {
  function getUseColor(value: number) {
    let color = colors.chart.green;

    if (value >= 80) {
      color = colors.chart.pink;
    } else if (value >= 60) {
      color = colors.chart.yellow;
    }

    return color;
  }
  return (
    <StatusCPUContainer>
      <StatusTitleWrapper>
        <label>CPU</label>
      </StatusTitleWrapper>
      <StatusCPUGrid>
        {data.map((cpu, index) => (
          <StatusChartWrapper key={index}>
            <Radial
              data={[cpu]}
              color={getUseColor(cpu)}
              valueShow={false}
              height={240}
            />
            <StatusCPUText>
              <StatusCPUTitle>{"CPU" + index}</StatusCPUTitle>
              <StatusCPUValue>{cpu + "%"}</StatusCPUValue>
            </StatusCPUText>
          </StatusChartWrapper>
        ))}
      </StatusCPUGrid>
    </StatusCPUContainer>
  );
};

export default Cpu;
