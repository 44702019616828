import styled from "styled-components";
import colors from "../global/colors";

export const PasswordModalWrapper = styled.form`
  padding: 60px 50px;
`;

export const PasswordModalInputWrapper = styled.div`
  margin-bottom: 10px;

  input {
    width: 256px;
    height: 50px;
    padding: 0 20px;
    border-radius: 5px;
    background-color: ${colors.btn.darkBlue};
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 500;
    color: ${colors.text.secondary};

    &:disabled {
      color: ${colors.text.secondary};
    }
  }
`;

export const PasswordModalSubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 143px;
    height: 50px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    font-family: SUIT;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
`;

export const PasswordModalCancel = styled.button`
  background: ${colors.btn.secondary};
  &:hover {
    background-color: ${colors.btn.secondaryHover};
  }
  &:active {
    background-color: ${colors.btn.secondaryActive};
  }
`;

export const PasswordModalSubmit = styled.button`
  background: ${colors.btn.primary};
  &:hover {
    background-color: ${colors.btn.primaryHover};
  }
  &:active {
    background-color: ${colors.btn.primaryActive};
  }
`;

export const ResignModalInfo = styled.p`
  font-family: SUIT;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: ${colors.text.info};
  margin-bottom: 40px;
  white-space: pre-line;
}
`;
export const ResignModalSubmit = styled.button`
  background: ${colors.btn.pink};
  &:hover {
    background-color: ${colors.btn.pinkHover};
  }
  &:active {
    background-color: ${colors.btn.pinkActive};
  }
`;
