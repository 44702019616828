// 이상 징후 감지 컨테이너 스타일
import styled from "styled-components";
import { dashboardBox } from "./Dashboard.styles";
import { FlexColumn, FlexRow } from "../common/Common.styles";

export const AnomalyDetectionContainer = styled(dashboardBox)`
  position: relative;
  //width: 390px;
  width: 740px;
  //height: 524px;
  height: 300px;
  margin-left: 10px;
`;

export const AnomalyDetectionProgressContainer = styled(FlexRow)`
  margin-top: 20px;
`;

export const AnomalyDetectionTemp = styled(FlexColumn)`
  width: 50%;
  height: 260px;
  border-right: 2px solid #5c617a;
`;
export const AnomalyDetectionStoreage = styled(FlexColumn)`
  width: 50%;
  height: 260px;
  margin-left: 20px;
`;
export const AnomalyDetectionTempLabel = styled(FlexRow)`
  color: #d7d7d7;
  font-weight: 600;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const AnomalyDetectionProgressBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const AnomalyDetectionProgressWrapper = styled.div`
  position: relative;
  width: 220px;
  height: 26px;
  border-radius: 15px;
  background-color: #262937;
`;

export const AnomalyDetectionProgress = styled.div<{ $width: string }>`
  width: ${(props) => `${props.$width}%`};
  height: 26px;
  border-radius: 15px;
  background-color: #ce417c;
`;

export const AnomalyDetectionProgressText = styled.label`
  position: absolute;
  left: 16px;
  top: 5px;
  font-family: SUIT;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  color: #0e0f16;
`;

export const AnomalyDetectionNaviboxTitle = styled.label`
  width: 100px;
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #d7d7d7;
`;

export const AnomalyDetectionLabel = styled.label`
  width: 60px;
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #5c617a;
  margin-left: 10px;
`;

// 데이터가 없는 경우
export const AnomalyDetectionBlankWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  color: #5c617a;
  label {
    margin-top: 40px;
  }
`;

export const AnomalyDetectionBlank = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #5c617a;
  margin-bottom: 30px;
  p {
    font-family: SUIT;
    font-size: 15px;
    margin-top: 10px;
  }
`;
