import React, { useEffect } from "react";
import Router from "./router/Router";
import { RouterProvider } from "react-router-dom";
import GlobalStyle from "./css/global/GlobalStyle.styles";
import i18next from "./lang/ i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
function App() {
  const queryClient = new QueryClient();
  useEffect(() => {
    // 다국어 처리
    const lang = localStorage.getItem("lang");
    lang && i18next.changeLanguage(lang);
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <RouterProvider router={Router} />
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}

export default App;
