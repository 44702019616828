import React, { useEffect, useRef, useState } from "react";
import {
  TimePickerContainer,
  TimePickerItem,
  TimePickerTime,
  TimePickerTitle,
  TimePickerWrapper,
} from "../../css/util/TimePicker.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setEndHour,
  setEndMin,
  setStartHour,
  setStartMin,
} from "../../store/features/timePikcerSlice";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

const TimeScroll = ({
  title,
  timeArr,
  timeLength,
  type,
}: {
  title: string;
  timeArr: string[];
  timeLength: number;
  type: "startHour" | "endHour" | "startMin" | "endMin";
}) => {
  const { t } = useTranslation();
  const scrollPosition = title === t("HOUR") ? 8 : 20;
  const dispatch = useDispatch();
  const [times, setTimes] = useState(timeArr);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const pickedStartHour = useSelector(
    (state: RootState) => state.timePicker.startTime,
  ).slice(0, 2);
  const pickedEndHour = useSelector(
    (state: RootState) => state.timePicker.endTime,
  ).slice(0, 2);
  const pickedStartMin = useSelector(
    (state: RootState) => state.timePicker.startTime,
  ).slice(-2);
  const pickedEndMin = useSelector(
    (state: RootState) => state.timePicker.endTime,
  ).slice(-2);
  const rotateTimes = (upward: boolean) => {
    if (upward) {
      setTimes((prev) => [
        ...prev.slice(timeLength),
        ...prev.slice(0, timeLength),
      ]);
    } else {
      setTimes((prev) => [
        ...prev.slice(-timeLength),
        ...prev.slice(0, -timeLength),
      ]);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const firstItem =
      container &&
      container.firstChild &&
      (container.firstChild.firstChild as HTMLDivElement);
    if (!container) return;
    const itemHeight = firstItem && firstItem.clientHeight;

    if (itemHeight && container.scrollTop <= itemHeight) {
      rotateTimes(true);
      container.scrollTop += itemHeight * (timeLength + scrollPosition);
    } else if (
      itemHeight &&
      container.scrollHeight - container.clientHeight - container.scrollTop <=
        itemHeight
    ) {
      rotateTimes(false);
      container.scrollTop -= itemHeight * (timeLength + scrollPosition);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const firstChild = container?.firstChild as HTMLDivElement;

    if (container) {
      const visibleHeight = container.clientHeight;
      container.addEventListener("scroll", handleScroll);

      // 아이템 높이 및 컨테이너 높이 구하기
      const itemHeight = 40;
      const firstHalf = times.slice(timeLength);

      // 선택된 시간이 첫 번째 배열에 위치하도록 조정
      let activeIndex;
      if (type === "startHour") {
        activeIndex = firstHalf.findIndex((time) => time === pickedStartHour);
      } else if (type === "startMin") {
        activeIndex = firstHalf.findIndex((time) => time === pickedStartMin);
      } else if (type === "endHour") {
        activeIndex = firstHalf.findIndex((time) => time === pickedEndHour);
      } else if (type === "endMin") {
        activeIndex = firstHalf.findIndex((time) => time === pickedEndMin);
      }

      // 스크롤 위치 초기화
      if (activeIndex !== undefined && activeIndex >= 0) {
        const scrollTo = itemHeight * activeIndex;
        container.scrollTop = scrollTo;
      }
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const value = event.currentTarget.textContent;
    if (type === "startHour") {
      dispatch(setStartHour(value));
    } else if (type === "startMin") {
      dispatch(setStartMin(value));
    } else if (type === "endHour") {
      dispatch(setEndHour(value));
    } else if (type === "endMin") {
      dispatch(setEndMin(value));
    }
  };

  const isActive = (time: string) => {
    let res = false;
    if (type === "startHour") {
      res = pickedStartHour === time;
    } else if (type === "startMin") {
      res = pickedStartMin === time;
    } else if (type === "endHour") {
      res = pickedEndHour === time;
    } else if (type === "endMin") {
      res = pickedEndMin === time;
    }
    return res;
  };

  return (
    <TimePickerItem>
      <TimePickerTitle>{title}</TimePickerTitle>
      <TimePickerContainer ref={containerRef}>
        <TimePickerWrapper>
          {times.map((time, index) => (
            <TimePickerTime
              type="button"
              key={index}
              $active={isActive(time)}
              onClick={(event) => handleClick(event)}
            >
              {time}
            </TimePickerTime>
          ))}
        </TimePickerWrapper>
      </TimePickerContainer>
    </TimePickerItem>
  );
};

export default React.memo(TimeScroll);
