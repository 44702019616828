import styled from "styled-components";
import { Content } from "../common/Common.styles";

export const DashboardContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: 945px;
`;
export const ContainerTitle = styled.div`
  font-family: SUIT;
  //font-size: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #d7d7d7;
`;

export const dashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b25;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
`;

export const TextBlue = styled.span`
  color: #467aff;
`;
export const TextGreen = styled.span`
  color: #2ee89a;
`;
export const TextPink = styled.span`
  color: #ce417c;
`;
export const TextGray = styled.span`
  color: #5c617a;
  padding-left: 5px;
`;

// 툴팁 컨테이너 스타일
export const TooltipContainer = styled.div`
  width: 188px;
  padding: 5px 10px;
  z-index: 10;
`;
