// 나비박스 상태 컨테이너 스타일
import styled from "styled-components";
import { dashboardBox } from "./Dashboard.styles";
import { FlexRow } from "../common/Common.styles";
import colors from "../global/colors";

export const NaviboxOperationContainer = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.$isLoading ? "" : "repeat(3, 1fr)"};
  justify-content: center;
  align-items: center;
`;

export const NaviboxOperationBox = styled.div<{ $active: boolean }>`
  cursor: pointer;
  padding: 20px 0;
  ${({ $active }) =>
    $active &&
    `
    &:hover {
      transform: scale(1.1);
    }
  `};
`;
export const NaviboxOperationDeviceName = styled.p<{ $active: boolean }>`
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => (props.$active ? "#fff" : colors.text.secondary)};
  text-align: center;
`;

export const NaviboxOperationStatus = styled.p<{ $active: boolean }>`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) =>
    props.$active ? colors.text.green : colors.text.secondary};
  text-align: center;
`;

export const NaviboxStatusContainer = styled(dashboardBox)`
  //width: 840px;
  width: 1200px;
  min-height: 210px;
  height: auto;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const NaviboxStatusChartContainer = styled.div`
  position: relative;
  margin-top: 15px;
`;

export const NaviboxStatusChartBox = styled.div`
  position: absolute;
`;

export const OperationChart = styled(NaviboxStatusChartBox)`
  left: -40px;
`;
export const AlgorithmChart = styled(NaviboxStatusChartBox)`
  left: 165px;
`;

export const BoardChart = styled(NaviboxStatusChartBox)`
  left: 375px;
`;
export const RecordChart = styled(NaviboxStatusChartBox)`
  left: 630px;
`;

export const NaviboxStatusChartWrapper = styled.div`
  position: relative;
`;

export const NaviboxStatusChartValueWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 85px;
  display: flex;
  justify-content: center;
`;

export const NaviboxStatusChartValue = styled.span`
  position: relative;
`;

export const NaviboxStatusChartLabelWrapper = styled(FlexRow)`
  justify-content: center;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
`;

export const NaviboxStatusChartTitle = styled.label`
  color: #d7d7d7;
  margin-right: 5px;
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
`;
