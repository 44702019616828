import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const initialState = {
  date: format(new Date(), "yyyyMMdd"),
  traffic_code: "",
  offset: -new Date().getTimezoneOffset(),
};

const trafficSearchSlice = createSlice({
  name: "trafficSearch",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setTrafficCode: (state, action) => {
      state.traffic_code = action.payload;
    },
  },
});

export default trafficSearchSlice.reducer;
export const { setDate, setTrafficCode } = trafficSearchSlice.actions;
