import React from "react";
import {
  NaviboxChart,
  NaviboxChartBox,
  NaviboxChartTitle,
  NaviboxChartTitleWrapper,
  NaviboxChartValue,
  NaviboxChartValueWrapper,
} from "../../../css/navibox/naviboxList/NaviboxList.styles";
import colors from "../../../css/global/colors";
import Radial from "../../../util/chart/Radial";

export const initialNavibosData = {
  cpu_temperature: "Navibox000",
  device_id: 0,
  device_name: "Navibox000",
  gpu_temperature: "0",
  is_connected: 0,
  ram_usage: "0",
  storage_usage: "0",
};
const ChartBox = ({
  value,
  title,
  $active,
}: {
  value: number;
  title: string;
  $active: boolean;
}) => {
  return (
    <NaviboxChartBox>
      <NaviboxChart>
        <Radial
          data={[value]}
          color={colors.chart.green}
          height={220}
          unit={"°C"}
          fontsize={"20px"}
        />
      </NaviboxChart>
      <NaviboxChartTitleWrapper>
        <NaviboxChartTitle $active={$active}>{title}</NaviboxChartTitle>
      </NaviboxChartTitleWrapper>
    </NaviboxChartBox>
  );
};

export default ChartBox;
