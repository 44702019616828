import React from "react";
import {
  NaviboxChartTitle,
  NaviboxStorageProgressBox,
  NaviboxStorageProgressGreen,
  NaviboxStorageProgressNameWrapper,
  NaviboxStorageProgressPink,
  NaviboxStorageProgressText,
  NaviboxStorageProgressWrapper,
} from "../../../css/navibox/naviboxList/NaviboxList.styles";

const StorageProgressBox = ({
  value,
  title,
  $active,
}: {
  value: number;
  title: string;
  $active: boolean;
}) => {
  return (
    <NaviboxStorageProgressBox>
      <NaviboxStorageProgressWrapper>
        {value >= 75 ? (
          <NaviboxStorageProgressPink
            value={value}
          ></NaviboxStorageProgressPink>
        ) : (
          <NaviboxStorageProgressGreen
            value={value}
          ></NaviboxStorageProgressGreen>
        )}

        <NaviboxStorageProgressText>{value}%</NaviboxStorageProgressText>
      </NaviboxStorageProgressWrapper>
      <NaviboxStorageProgressNameWrapper>
        <NaviboxChartTitle $active={$active}>{title}</NaviboxChartTitle>
      </NaviboxStorageProgressNameWrapper>
    </NaviboxStorageProgressBox>
  );
};

export default StorageProgressBox;
