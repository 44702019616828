import styled from "styled-components";
import colors from "../../global/colors";

export const DetailTabBox = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

export const DetailTabMenu = styled.li<{ $clicked: boolean }>`
  width: 177px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: SUIT;
  font-size: 18px;
  font-weight: 600;
  white-space: pre-line;
  color: ${(props) => (props.$clicked ? "#fff" : colors.text.secondary)};
  cursor: pointer;
  border-bottom: ${(props) => (props.$clicked ? "4px solid #d9d9d9" : "none")};
`;
