import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../common/Common.styles";
import colors from "../../global/colors";

// 별도의 컴포넌트 스타일 정의
export const NaviboxContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

export const NaviboxBox = styled(FlexRow)<{ $active: boolean }>`
  width: 1360px;
  justify-content: center;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;

  ${({ $active }) =>
    $active &&
    `
    &:hover {
      transform: scale(1.008);
    }
  `}
`;

export const NaviboxVideo = styled.div`
  width: 304px;
  height: 228px;
  background: black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  video {
    width: 304px;
    height: 228px;
    background: black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

export const NaviboxStatusContainer = styled(FlexColumn)`
  width: 956px;
  //width: 800px;
  height: 198px;
  position: relative;
  background-color: ${colors.text.black};
  padding: 30px 70px 0 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const NaviboxNameWrapper = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  font-family: SUIT;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => (props.$active ? "#467aff" : "#5c617a")};
  gap: 12px;
  svg {
    margin-top: 4px;
    fill: ${(props) => (props.$active ? "#467aff" : "#5c617a")};
  }
`;

export const NaviboxStatusChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //justify-content: center;
  //gap: 120px;
  align-items: center;
`;

export const NaviboxChartBox = styled.div`
  position: relative;
  margin-left: 30px;
`;

export const NaviboxChart = styled.div`
  padding-top: 10px;
  height: 120px;
  width: 150px;
`;
export const NaviboxChartValueWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 83px;
  display: flex;
  justify-content: center;
`;
export const NaviboxChartValue = styled.label<{ $active: boolean }>`
  font-family: Gilroy;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: ${(props) => (props.$active ? "#fff" : "#5c617a")};
`;
export const NaviboxChartTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const NaviboxChartTitle = styled.div<{ $active: boolean }>`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => (props.$active ? "#d7d7d7" : "#5c617a")};
`;

export const NaviboxStatusClickArea = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 5;
`;

export const NaviboxStorageProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NaviboxStorageProgressBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const NaviboxStorageProgressWrapper = styled.div`
  position: relative;
  width: 220px;
  height: 26px;
  border-radius: 15px;
  background-color: #262937;
`;

export const NaviboxStorageProgress = styled.div`
  width: 0;
  height: 26px;
  border-radius: 15px;
  background-color: ${colors.box.green};
`;

export const NaviboxStorageProgressGreen = styled(NaviboxStorageProgress)<{
  value: number;
}>`
  width: ${(props) => props.value}%;
  background-color: ${colors.chart.green};
`;
export const NaviboxStorageProgressPink = styled(NaviboxStorageProgress)<{
  value: number;
}>`
  width: ${(props) => props.value}%;
  background-color: ${colors.chart.pink};
`;

export const NaviboxStorageProgressText = styled.span`
  position: absolute;
  left: 16px;
  top: 5px;
  font-family: SUIT, sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  color: #0e0f16;
`;

export const NaviboxStorageProgressNameWrapper = styled.div`
  margin-left: 20px;
`;

export const NaviboxStorageProgressName = styled.span<{ active: boolean }>`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${(props) => (props.active ? "#d7d7d7" : "#5c617a")};
`;

export const NaviboxCaptureButtonWrapper = styled.div<{ $active: boolean }>`
  width: 155px;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background: ${(props) =>
    props.$active ? colors.btn.darkGray : colors.btn.unactive};
  cursor: ${(props) => (props.$active ? "pointer" : "basic")};
  z-index: 10;
  color: ${(props) => (props.$active ? "#fff" : colors.text.secondary)};
  &:hover {
    background: ${(props) => (props.$active ? "#323d7e" : colors.btn.unactive)};
  }
`;

export const NaviboxCaptureButtonTitle = styled.span`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  white-space: pre-line;
`;

// Loader 스타일
export const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  z-index: 1001;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  opacity: 0.8;
`;
