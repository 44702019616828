import { configureStore } from "@reduxjs/toolkit";
import tabSlice from "./features/tabSlice";
import modalSlice from "./features/modalSlice";
import calendarSlice from "./features/calendarSlice";
import timePikcerSlice from "./features/timePikcerSlice";
import toastSlice from "./features/toastSlice";
import trafficSearchSlice from "./features/trafficSearchSlice";
import naviboxSlice from "./features/naviboxSlice";
import logSearchSlice from "./features/logSearchSlice";

const store = configureStore({
  reducer: {
    navibox: naviboxSlice,
    tab: tabSlice,
    modal: modalSlice,
    calendar: calendarSlice,
    timePicker: timePikcerSlice,
    toast: toastSlice,
    trafficSearch: trafficSearchSlice,
    logSearch: logSearchSlice,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
