import React, { useEffect, useRef } from "react";
import { ToastMessage, ToastWrapper } from "../css/global/Toast.styles";
import { ReactComponent as IcCheck } from "../asset/icons/ic_check.svg";
import { useDispatch, useSelector } from "react-redux";
import { setToastOpen } from "../store/features/toastSlice";
import { RootState } from "../store/store";

const Toast = ({
  message,
  top,
  right,
}: {
  message: string;
  top?: number;
  right?: number;
}) => {
  const toastRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const toastOpen = useSelector((state: RootState) => state.toast.open);
  useEffect(() => {
    if (toastOpen && toastRef.current) {
      toastRef.current.style.opacity = "1";
      const timer = setTimeout(() => {
        if (toastRef.current) {
          toastRef.current.style.opacity = "0";
          dispatch(setToastOpen(false));
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toastOpen]);

  useEffect(() => {
    if (toastRef.current) {
      if (top !== undefined) {
        toastRef.current.style.top = `${top}px`;
      }
      if (right !== undefined) {
        toastRef.current.style.right = `${right}px`;
      }
    }
  }, [top, right]);

  return (
    <ToastWrapper ref={toastRef}>
      <IcCheck />
      <ToastMessage>{message}</ToastMessage>
    </ToastWrapper>
  );
};

export default Toast;
