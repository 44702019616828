import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: "TRAFFIC_TAB",
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export default tabSlice.reducer;
export const { setActive } = tabSlice.actions;
