import React, { useEffect, useState } from "react";
import {
  LogBox,
  LogNoData,
  LogTextContainer,
  LogTextScrollBox,
  LogTextWrapper,
  LogTitleWrapper,
  LogWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/LogTab.styles";
import LogSearch from "./LogSearch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../../api/DataApi";
import { format } from "date-fns";
import Spinner from "../../../../../../util/Spinner";
import { useTranslation } from "react-i18next";

export interface INaviBoxLogParams {
  log_type: string;
  start_time: string;
  end_time: string;
  text?: string;
  offset: number
}

const LogContent = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    log_type: "",
    start_time: "",
    end_time: "",
    text: "",
    offset: 0
  });
  const deviceName = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );
  const originDate = useSelector(
    (state: RootState) => state.calendar.selectedLogDate,
  );
  const startTime = useSelector(
    (state: RootState) => state.timePicker.startTime,
  );
  const endTime = useSelector((state: RootState) => state.timePicker.endTime);
  const date = format(new Date(originDate), "yyyyMMdd");
  const [time, setTime] = useState({
    start_time: startTime.replace(":", ""),
    end_time: endTime.replace(":", ""),
  });
  const log_type = useSelector((state: RootState) => state.logSearch.log_type);
  const text = useSelector((state: RootState) => state.logSearch.text);
  const [logData, setLogData] = useState<{ [key: string]: string[] }>({});
  useEffect(() => {
    setParams({
      log_type: log_type,
      start_time: time.start_time,
      end_time: time.end_time,
      text: text,
      offset: 0
    });
  }, [log_type, time, text]);

  const { data, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: ["naviboxLog", deviceName, date, params],
    queryFn: () =>
      DataApi.naviboxLog(deviceName, date, {
        log_type: log_type,
        start_time: time.start_time,
        end_time: time.end_time,
        text: text,
        offset: -new Date().getTimezoneOffset()
      }),
  });

  useEffect(() => {
    if (isSuccess) {
      Object.entries(logData).map(([key, value]) => {});
      setLogData(data);
    }
  }, [data]);

  return (
    <>
      <LogBox>
        <LogSearch setTime={setTime} />
        <LogTextContainer>
          <LogTextScrollBox>
            {isSuccess && logData && Object.keys(logData).length > 0 ? (
              Object.entries(logData).map(([key, value], index) => (
                <LogWrapper key={index}>
                  <LogTitleWrapper>
                    <label>{key}</label>
                    <label>{log_type}</label>
                  </LogTitleWrapper>
                  <LogTextWrapper>
                    {value.map((log: string, index) => (
                      <label key={index}>{log}</label>
                    ))}
                  </LogTextWrapper>
                </LogWrapper>
              ))
            ) : isLoading || isFetching ? (
              <Spinner />
            ) : (
              <LogNoData>
                <p>{t("NO_LOG_EVENTS")}</p>
              </LogNoData>
            )}
          </LogTextScrollBox>
        </LogTextContainer>
      </LogBox>
    </>
  );
};

export default LogContent;
