import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  log_type: "ALL",
  text: "",
};

const logSearchSlice = createSlice({
  name: "trafficSearch",
  initialState,
  reducers: {
    setLogType: (state, action) => {
      state.log_type = action.payload;
    },
    setText: (state, action) => {
      state.text = action.payload;
    },
  },
});

export default logSearchSlice.reducer;
export const { setLogType, setText } = logSearchSlice.actions;
