import styled, { css, keyframes } from "styled-components";
import { CellProps } from "../../interface/Calendar";
import colors from "../global/colors";
import { FlexColumn } from "../common/Common.styles";

export const SmCalendarWrapper = styled.div`
  z-index: 50;
  background-color: ${colors.box.darkBox};
  position: absolute;
  top: 78px;
  left: 36px;
  width: 270px;
  height: 300px;
  padding: 25px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* shadow-lg */
`;

export const LCalendarWrapper = styled.div`
  width: 320px;
  height: 364px;
`;

export const GridWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  text-align: center;
  grid-template-rows: repeat(8, 1fr);
`;

export const CalendarThisMonth = styled.div`
  font-family: SUIT;
  font-weight: 600;
  color: #fff;
  grid-column: span 5;
  justify-self: start;
`;

export const CalendarMonthChange = styled.div`
  grid-column: span 2;
  justify-self: center;
  color: ${colors.text};
  button {
    font-family: SUIT;
    font-weight: 600;
    color: #fff;
  }
`;

export const CalendarWeeks = styled.div`
  font-family: SUIT;
  font-weight: 500;
  color: ${colors.text.secondary};
  padding-bottom: 10px;
`;

export const PrefixDays = styled.div`
  color: ${colors.text.secondary};
`;
export const Day = styled.div<CellProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  border-radius: 50%;
  color: #d7d7d7;
  cursor: pointer;
  border: ${(props) => (props.$today ? "1px solid #2ee89a" : "none")};
  ${({ $dayIndex }) => {
    if ($dayIndex === 0) return "color: rgb(255, 77, 77);";
    if ($dayIndex === 6) return "color: rgb(77, 77, 255);";
  }}
  ${({ $isActive, disabled, onClick }) => css`
    ${!disabled &&
    !$isActive &&
    onClick &&
    `
        cursor: pointer;
        &:hover {
          background-color: ${colors.btn.primaryHover};
          color: #d7d7d7;
        }
        &:active {
          background-color: ${colors.btn.primaryActive};
          color: #d7d7d7;
        }

      `}
    ${$isActive &&
    `   color: #d7d7d7;
        font-weight: bold;
        background-color: ${colors.btn.primary};
      `}
    ${disabled && ` background-color: ${colors.btn.secondary}`}
  `};
`;
export const DayDotWrapper = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
`;

export const HasLogDot = styled.div<{ $isVisible: boolean }>`
  margin-top: 2px;
  border: 3px solid #114ce4;
  border-radius: 3px;
  background-color: #114ce4;
  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
`;
