import React, { useEffect, useState } from "react";
import {
  BtnContainer,
  InputContainer,
  LabelWrapper,
  CancelBtn,
  RightBtn,
  Tab,
  TabContainer,
  TabContent,
  UserHelpBox,
} from "../css/login/FindAccount.styles";
import { LoginContainer, LoginIconWrapper } from "../css/login/Login.styles";
import { ReactComponent as IcOffice } from "../asset/icons/ic_office.svg";
import { ReactComponent as IcPhone } from "../asset/icons/ic_phone.svg";
import { ReactComponent as IcUser } from "../asset/icons/ic_user.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { LoginApi } from "../api/LoginApi";
import { useMutation } from "@tanstack/react-query";
import { ILoginProps } from "./Login";
const FindAccount = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm<ILoginProps>();
  const [activeTab, setActiveTab] = useState<"id" | "password">("id");
  const { t } = useTranslation();
  useEffect(() => {
    reset();
  }, [activeTab]);

  const mutation = useMutation<string, string, ILoginProps>({
    mutationFn: (param: ILoginProps) => LoginApi.findId(param),
  });
  const onValid = (data: ILoginProps) => {
    if (mutation.isPending) return;
    mutation.mutate(data);
  };
  return (
    <LoginContainer>
      <UserHelpBox>
        <TabContainer>
          <div>
            <Tab
              tabtype={"id"}
              $active={activeTab === "id"}
              onClick={() => setActiveTab("id")}
            >
              {t("ID_TAB")}
            </Tab>
            <Tab
              tabtype={"password"}
              $active={activeTab === "password"}
              onClick={() => setActiveTab("password")}
            >
              {t("PW_TAB")}
            </Tab>
          </div>
        </TabContainer>

        <form onSubmit={handleSubmit(onValid)}>
          <TabContent className="current">
            <LabelWrapper>
              <label>
                {activeTab === "id" ? t("ID_TAB_DESC") : t("PW_TAB_DESC")}
              </label>
            </LabelWrapper>
            <div>
              <InputContainer>
                <LoginIconWrapper>
                  {activeTab === "id" ? <IcOffice /> : <IcUser />}
                </LoginIconWrapper>
                <input
                  type="text"
                  placeholder={
                    activeTab === "id"
                      ? t("ID_TAB_COMPANY_PLACEHOLDER")
                      : t("PW_TAB_ID_PLACEHOLDER")
                  }
                  required
                  {...register(activeTab === "id" ? "company" : "id", {
                    required: "",
                  })}
                />
              </InputContainer>
              <InputContainer>
                <LoginIconWrapper>
                  <IcPhone />
                </LoginIconWrapper>
                <input
                  type="text"
                  placeholder={t("ID_TAB_PHONE_PLACEHOLDER")}
                  required
                  {...register("phone_num", {
                    required: "",
                  })}
                />
              </InputContainer>
              <BtnContainer>
                <CancelBtn type="button" onClick={() => navigate("/login")}>
                  {t("CANCEL_BTN")}
                </CancelBtn>
                <RightBtn type="submit">
                  {activeTab === "id"
                    ? t("ID_TAB_CONFIRM_BTN")
                    : t("PW_TAB_CONFIRM_BTN")}
                </RightBtn>
              </BtnContainer>
            </div>
          </TabContent>
        </form>
      </UserHelpBox>
    </LoginContainer>
  );
};

export default FindAccount;
