import React, { useEffect, useState } from "react";
import {
  PwModifyBtn,
  Resign,
  UserAccountBox,
  UserAccountContainer,
  UserAccountContentWrapper,
  UserAccountSubmitWrapper,
  UserAccountTitle,
  UserAccountWrapper,
} from "../css/account/Account.styles";
import UserAccountScreen from "../content/account/UserAccountScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setModalOpen } from "../store/features/modalSlice";
import Modal from "../util/Modal";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DataApi } from "../api/DataApi";
import { SubmitHandler, useForm } from "react-hook-form";
import Toast from "../util/Toast";
import { setToastMsg, setToastOpen } from "../store/features/toastSlice";

interface IModifyFormData {
  phone_num: string;
}

export interface IUpdateForm extends IModifyFormData {
  start_screen: string;
}
const Account = () => {
  const { t } = useTranslation();
  const isModalOpen = useSelector((state: RootState) => state.modal.open);
  const isToastOpen = useSelector((state: RootState) => state.toast.open);
  const toastMsg = useSelector((state: RootState) => state.toast.msg);
  const [modalType, setModalType] = useState<"password" | "resign">("password");
  const screen = localStorage.getItem("startScreen");
  const [selectedScreen, setSelectedScreen] = useState(screen || "division_16");
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<IModifyFormData>();
  const [info, setInfo] = useState({
    company: "",
    id: 0,
    phone_num: "",
    start_screen: "",
    user_id: "",
  });
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["accountInfo"],
    queryFn: DataApi.accountInfo,
  });

  useEffect(() => {
    if (isSuccess) {
      setInfo(data);
    }
  }, [data]);

  const handleModalBtnClick = (type: "password" | "resign") => {
    if (isLoading) return;
    setModalType(type);
    dispatch(setModalOpen(true));
  };
  const mutation = useMutation<string, string, IUpdateForm>({
    mutationFn: (param: IUpdateForm) => DataApi.updateAccountInfo(param),
    onSuccess: (data) => {
      if (Boolean(data)) {
        dispatch(setToastMsg(t("COMPLETE_MODIFY")));
      }
      dispatch(setToastOpen(true));
    },
  });

  const onValid: SubmitHandler<IModifyFormData> = (data) => {
    if (mutation.isPending) return;
    dispatch(setToastMsg(t("NO_CHANGES")));
    if (data.phone_num.length === 0) data.phone_num = info.phone_num;
    mutation.mutate({
      phone_num: data.phone_num,
      start_screen: selectedScreen,
    });
  };
  return (
    <>
      {isToastOpen && <Toast message={toastMsg} top={150} right={300} />}
      {isModalOpen && <Modal type={modalType} />}

      <UserAccountContainer>
        <UserAccountBox onSubmit={handleSubmit(onValid)}>
          <UserAccountWrapper>
            <UserAccountTitle>
              <label>{t("ID")}</label>
            </UserAccountTitle>
            <UserAccountContentWrapper>
              <input readOnly={true} value={info.user_id} disabled={true} />
            </UserAccountContentWrapper>
          </UserAccountWrapper>
          <UserAccountWrapper>
            <UserAccountTitle>
              <label>{t("PASSWORD")}</label>
            </UserAccountTitle>
            <UserAccountContentWrapper>
              <input readOnly={true} value={"*******"} disabled={true} />
              <PwModifyBtn
                type={"button"}
                onClick={() => handleModalBtnClick("password")}
              >
                {t("CHANGE")}
              </PwModifyBtn>
            </UserAccountContentWrapper>
          </UserAccountWrapper>
          <UserAccountWrapper>
            <UserAccountTitle>
              <label>{t("COMPANY_NAME")}</label>
            </UserAccountTitle>
            <UserAccountContentWrapper>
              <input readOnly={true} value={info.company} disabled={true} />
            </UserAccountContentWrapper>
          </UserAccountWrapper>
          <UserAccountWrapper>
            <UserAccountTitle>
              <label>{t("PHONE_NUM")}</label>
            </UserAccountTitle>
            <UserAccountContentWrapper>
              <input
                defaultValue={info.phone_num}
                disabled={false}
                {...register("phone_num")}
              />
            </UserAccountContentWrapper>
          </UserAccountWrapper>
          <UserAccountWrapper>
            <UserAccountTitle>
              <label>{t("START_SCREEN")}</label>
            </UserAccountTitle>
            <UserAccountContentWrapper>
              <UserAccountScreen
                setSelectedScreen={setSelectedScreen}
                selectedScreen={selectedScreen}
              />
            </UserAccountContentWrapper>
          </UserAccountWrapper>
          <UserAccountSubmitWrapper>
            <Resign onClick={() => handleModalBtnClick("resign")}>
              {t("WITHDRAWAL_MEMBER")}
            </Resign>
            <button type="submit">{t("MODIFY")}</button>
          </UserAccountSubmitWrapper>
        </UserAccountBox>
      </UserAccountContainer>
    </>
  );
};

export default Account;
