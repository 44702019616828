import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../page/Login";
import FindAccount from "../page/FindAccount";
import Layout from "../content/Layout";
import Dashboard from "../page/Dashboard";

import Account from "../page/Account";
import NaviboxDetail from "../page/NaviboxDetail";
import NaviboxList from "../page/NaviboxList";
import Division4 from "../page/Division4";
import Division9 from "../page/Division9";
import Division16 from "../page/Division16";
import NotFound from "../page/NotFound";

const Router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/find-account", element: <FindAccount /> },
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "dashboard", element: <Dashboard /> },
      {
        path: "navibox",
        children: [
          { index: true, element: <NaviboxList /> },
          { path: ":id", element: <NaviboxDetail /> },
        ],
      },

      { path: "account", element: <Account /> },
      {
        path: "video",
        children: [
          { path: "4", element: <Division4 /> },
          { path: "9", element: <Division9 /> },
          { path: "16", element: <Division16 /> },
          { path: "*", element: <NotFound /> },
        ],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

export default Router;
