import React, { useEffect, useState } from "react";
import {
  DateTabContainer,
  DateTabMenu,
  TrafficAnalysisContainer,
  TrafficFlexRow,
  TrafficRiskBox,
  TrafficRiskChart,
} from "../../../../../css/navibox/naviboxDetail/tabContent/TrafficTab.styles";
import { FlexColumn } from "../../../../../css/common/Common.styles";
import TrafficRiskChartBox from "./TrafficRiskChartBox";
import { useTranslation } from "react-i18next";
import { DataApi } from "../../../../../api/DataApi";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

import { setTrafficCode } from "../../../../../store/features/trafficSearchSlice";

import TrafficChartSection from "./TrafficChartSection";
import colors from "../../../../../css/global/colors";
import TrafficVolumeChartBox from "./TrafficVolumeChartBox";
import Spinner from "../../../../../util/Spinner";

const TrafficTab = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.navibox.id);
  const timeOffset = -new Date().getTimezoneOffset();
  const deviceName = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );
  const { t } = useTranslation();
  const periods = [
    "week1",
    "week2",
    "month1",
    "month3",
    "month6",
    "year1",
    "all",
  ];
  const [selectedPeriod, setSelectedPeriod] = useState("week1");
  const [chartBoxData, setChartBoxData] = useState({
    l2rVol: {},
    l2rSpeed: {},
    r2lVol: {},
    r2lSpeed: {},
    risk: {},
  });

  useEffect(() => {
    dispatch(setTrafficCode(""));
  }, []);

  const {
    data,
    isSuccess,
    isLoading: isDataLoading,
    isFetching: isDataFetching,
  } = useQuery({
    queryKey: ["naviboxTraffic", selectedPeriod, id],
    queryFn: () => DataApi.naviboxTrafficData(id, selectedPeriod, timeOffset),
    enabled: id.length > 0,
  });

  const formattedData = (data: any, dataName: string) => {
    const res = { data: {}, date: [] };
    const detailData = data[dataName].map((item: any) => item);

    res.data = {
      name: deviceName,
      data: detailData.map((item: any) => item.num),
    };
    res.date = detailData.map((item: any) => item.date);

    return res;
  };

  useEffect(() => {
    if (isSuccess) {
      setChartBoxData({
        l2rVol: formattedData(data, "L2R-VOLUME"),
        l2rSpeed: formattedData(data, "L2R-SPEED"),
        r2lVol: formattedData(data, "R2L-VOLUME"),
        r2lSpeed: formattedData(data, "R2L-SPEED"),
        risk: formattedData(data, "RISK"),
      });
    }
  }, [data]);

  const clickTabMenu = (period: string) => {
    setSelectedPeriod(period);
  };
  return (
    <TrafficAnalysisContainer>
      <DateTabContainer>
        {periods.map((period, index) => (
          <DateTabMenu
            key={index}
            $clicked={selectedPeriod === period}
            onClick={() => setSelectedPeriod(period)}
          >
            {t(period)}
          </DateTabMenu>
        ))}
      </DateTabContainer>
      {/*<FlexColumn>*/}
      {/*  <TrafficFlexRow>*/}
      {/*    /!*{Object.keys(chartBoxData.l2rVol).length > 0 && (*!/*/}
      {/*    <TrafficChartSection*/}
      {/*      data={chartBoxData.l2rVol}*/}
      {/*      type={"L2R-VOLUME"}*/}
      {/*      titleKey={"VOLUME_RIGHT_TITLE"}*/}
      {/*      chartColor={colors.chart.blue}*/}
      {/*    />*/}
      {/*    /!*)}*!/*/}
      {/*    /!*{Object.keys(chartBoxData.l2rSpeed).length > 0 && (*!/*/}
      {/*    <TrafficChartSection*/}
      {/*      data={chartBoxData.l2rSpeed}*/}
      {/*      type={"L2R-SPEED"}*/}
      {/*      titleKey={"SPEED_RIGHT_TITLE"}*/}
      {/*      chartColor={colors.chart.purple}*/}
      {/*    />*/}
      {/*    /!*)}*!/*/}
      {/*  </TrafficFlexRow>*/}
      {/*  <TrafficFlexRow>*/}
      {/*    /!*{Object.keys(chartBoxData.r2lVol).length > 0 && (*!/*/}
      {/*    <TrafficChartSection*/}
      {/*      data={chartBoxData.r2lVol}*/}
      {/*      type={"R2L-VOLUME"}*/}
      {/*      titleKey={"VOLUME_LEFT_TITLE"}*/}
      {/*      chartColor={colors.chart.blue}*/}
      {/*    />*/}
      {/*    /!*)}*!/*/}
      {/*    /!*{Object.keys(chartBoxData.r2lSpeed).length > 0 && (*!/*/}
      {/*    <TrafficChartSection*/}
      {/*      data={chartBoxData.r2lSpeed}*/}
      {/*      type={"R2L-SPEED"}*/}
      {/*      titleKey={"SPEED_LEFT_TITLE"}*/}
      {/*      chartColor={colors.chart.purple}*/}
      {/*    />*/}
      {/*    /!*)}*!/*/}
      {/*  </TrafficFlexRow>*/}
      {/*</FlexColumn>*/}
      {/*{Object.keys(chartBoxData.risk).length > 0 && (*/}
      {/*  <TrafficVolumeChartBox*/}
      {/*    data={chartBoxData.risk}*/}
      {/*    type={"RISK-RISK"}*/}
      {/*    titleKey={"RISK"}*/}
      {/*    chartColor={colors.chart.blue}*/}
      {/*  />*/}
      {/*)}*/}
      {Object.keys(chartBoxData.risk).length > 0 ? (
        <TrafficRiskChartBox
          data={chartBoxData.risk}
          type={"RISK-RISK"}
          titleKey={"RISK"}
          chartColor={colors.chart.purple}
        />
      ) : (
        <TrafficRiskBox>
          <TrafficRiskChart>
            <Spinner size={70} />
          </TrafficRiskChart>
        </TrafficRiskBox>
      )}
    </TrafficAnalysisContainer>
  );
};

export default TrafficTab;
