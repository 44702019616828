import React, { SetStateAction, useEffect, useState } from "react";
import {
  ImagePoster,
  VideoBlankWrapper,
  VideoItem,
  VideoListBox,
  VideoListScroll,
  VideoPoster,
  VideoText,
  VideoTextDate,
  VideoTextTime,
  VideoTextWrapper,
} from "../../../../../css/navibox/naviboxDetail/tabContent/VideoTab.styles";
import {
  ImageCaptrueTab,
  ImageJaywalkingTab,
  ImageTabButton,
  ImageTabContainer,
} from "../../../../../css/navibox/naviboxDetail/tabContent/ImageTab.styles";
import { ReactComponent as IcImage } from "../../../../../asset/icons/ic_image.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { format, parse } from "date-fns";
import Spinner from "../../../../../util/Spinner";
import { ReactComponent as IcCheck } from "../../../../../asset/icons/ic_check.svg";
import { SpinnerWrapper } from "../../../../../css/util/Spinner.styles";
const ImageSearchRes = ({
  setImageLink,
}: {
  setImageLink: React.Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const azureBaseURL = process.env.REACT_APP_AZURE_BASE_URL;
  const [images, setImages] = useState<string[]>([]);
  const deviceName = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );
  const originDate = useSelector(
    (state: RootState) => state.calendar.selectedImageDate,
  );
  const offset = -new Date().getTimezoneOffset();

  const date = originDate.replaceAll(".", "").replaceAll(" ", "");
  const [activeCam, setActiveCam] = useState(0);
  const [clickedImage, setClickedImage] = useState(-1);
  const { data, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: ["naviboxImage", deviceName, date, activeCam],
    queryFn: () => DataApi.naviboxImage(deviceName, date, offset, activeCam),
    refetchInterval: 5000,
  });
  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
      const filteredData = data.filter((item: string) => {
        console.log("item", item);
        const partsAfterSecondSlash = item.split("/").slice(2).join("/");
        const [datePart, ...timeParts] = partsAfterSecondSlash.split(/[_\/\.]/);
        const [hour, minute, second] = timeParts;

        let fileDate = new Date(
          `${datePart.substring(0, 4)}-${datePart.substring(
            4,
            6,
          )}-${datePart.substring(6, 8)}T${hour}:${minute}:${second}Z`,
        );
        console.log("fileDate", fileDate);

        const fileDateString = `${fileDate.getFullYear()}${String(
          fileDate.getMonth() + 1,
        ).padStart(2, "0")}${String(fileDate.getDate()).padStart(2, "0")}`;
        // console.log("fileDateString", fileDateString);
        // console.log("date", date);
        // console.log(
        //   "fileDateString === date",
        //   fileDateString,
        //   date,
        //   fileDateString === date,
        // );
        return fileDateString === date;
      });
      // console.log("filteredData", filteredData);
      setImages(filteredData);
      // setImages(data);
    }
  }, [data]);
  const handleImageClick = (index: number, link: string) => {
    setClickedImage(index);
    setImageLink(link);
  };

  const adjustFilesWithOffset = (fileName: string) => {
    // const [datePart, hour, minute, second] = fileName.split(/[_\.]/);
    // const year = datePart.substring(0, 4);
    // const month = datePart.substring(4, 6);
    // const day = datePart.substring(6, 8);

    const partsAfterSecondSlash = fileName.split("/").slice(2).join("/");

    const [datePart, ...timeParts] = partsAfterSecondSlash.split(/[_\/\.]/);

    const year = datePart.substring(0, 4);
    const month = datePart.substring(4, 6);
    const day = datePart.substring(6, 8);
    const [hour, minute, second] = timeParts;

    let fileDate = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}Z`,
    );

    fileDate.setMinutes(fileDate.getMinutes() + offset);

    const newFileName = `${fileDate.getUTCFullYear()}${String(
      fileDate.getUTCMonth() + 1,
    ).padStart(2, "0")}${String(fileDate.getUTCDate()).padStart(
      2,
      "0",
    )}_${String(fileDate.getUTCHours()).padStart(2, "0")}_${String(
      fileDate.getUTCMinutes(),
    ).padStart(2, "0")}_${String(fileDate.getUTCSeconds()).padStart(
      2,
      "0",
    )}.png`;

    return newFileName;
  };

  return (
    <VideoListScroll>
      <ImageTabContainer>
        <ImageJaywalkingTab
          $active={activeCam === 0}
          onClick={() => setActiveCam(0)}
        >
          Camera 1
        </ImageJaywalkingTab>
        {/*<ImageTabButton*/}
        {/*  $active={activeCam === "RISK"}*/}
        {/*  onClick={() => setActiveCam("RISK")}*/}
        {/*>*/}
        {/*  {t("RISK")}*/}
        {/*</ImageTabButton>*/}
        <ImageCaptrueTab
          $active={activeCam === 1}
          onClick={() => setActiveCam(1)}
        >
          Camera 2
        </ImageCaptrueTab>
      </ImageTabContainer>
      <VideoListBox>
        {isSuccess && images.length > 0 ? (
          images.map((link: string, index) => {
            const utcLink = adjustFilesWithOffset(link);
            const arr = utcLink.split("_");
            return (
              <VideoItem
                onClick={() => handleImageClick(index, link)}
                key={index}
                $active={clickedImage === index}
              >
                <ImagePoster
                  className="navibox-image-poster"
                  $active={clickedImage === index}
                  src={`${azureBaseURL}/capture/${link}`}
                />

                <VideoTextWrapper>
                  <VideoText>
                    <VideoTextDate>
                      {format(
                        parse(arr[0], "yyyyMMdd", new Date()),
                        "yyyy. MM. dd.",
                      )}
                    </VideoTextDate>
                    <VideoTextTime>
                      {arr[1]} : {arr[2]} : {arr[3].slice(0, 2)}
                    </VideoTextTime>
                  </VideoText>
                  {clickedImage === index ? <IcCheck /> : null}
                </VideoTextWrapper>
              </VideoItem>
            );
          })
        ) : isLoading || isFetching ? (
          <SpinnerWrapper>
            <Spinner size={30} />
          </SpinnerWrapper>
        ) : (
          <VideoBlankWrapper>
            <IcImage />
            <label>{t("BLANK_IMAGE")}</label>
          </VideoBlankWrapper>
        )}
      </VideoListBox>
    </VideoListScroll>
  );
};

export default ImageSearchRes;
