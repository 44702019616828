import React from "react";
import { useDispatch } from "react-redux";
import {
  PasswordModalCancel,
  PasswordModalInputWrapper,
  PasswordModalSubmitWrapper,
  PasswordModalWrapper,
  ResignModalInfo,
  ResignModalSubmit,
} from "../../css/account/Modal.styles";
import { ModalErrorWrapper } from "../../css/util/Modal.styles";
import { setModalOpen } from "../../store/features/modalSlice";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { DataApi } from "../../api/DataApi";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export interface IResignForm {
  password: string;
}

const ResignModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IResignForm>();

  const mutation = useMutation<string, string, IResignForm>({
    mutationFn: (data) => DataApi.deleteAccount(data),
    onSuccess: (res) => {
      if (Boolean(res)) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login", { replace: true });
      } else {
        setError("password", { message: t("NO_MATCH_PASSWORD") });
      }
    },
    onError: () => setError("password", { message: t("NO_MATCH_PASSWORD") }),
  });
  const onValid = (data: IResignForm) => {
    if (mutation.isPending) return;
    mutation.mutate(data);
  };

  return (
    <PasswordModalWrapper onSubmit={handleSubmit(onValid)}>
      <ResignModalInfo>{t("RESIGN_MODAL_DESC")}</ResignModalInfo>
      <PasswordModalInputWrapper>
        <input
          type="password"
          placeholder={t("PASSWORD")}
          {...register("password", { required: true })}
        />
      </PasswordModalInputWrapper>
      <ModalErrorWrapper>
        <label>{errors.password?.message}</label>
      </ModalErrorWrapper>
      <PasswordModalSubmitWrapper>
        <PasswordModalCancel
          type={"button"}
          onClick={() => dispatch(setModalOpen(false))}
        >
          {t("CANCEL")}
        </PasswordModalCancel>
        <ResignModalSubmit>{t("CONFIRM")}</ResignModalSubmit>
      </PasswordModalSubmitWrapper>
    </PasswordModalWrapper>
  );
};

export default ResignModal;
