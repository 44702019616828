import { createSlice } from "@reduxjs/toolkit";
import { format, subHours } from "date-fns";

const now = new Date();
const threeHoursAgo = subHours(now, 3);
const twoHoursAgo = subHours(now, 2);

const initialState = {
  open: false,
  startTime: format(threeHoursAgo, "HH:mm"),
  endTime: format(twoHoursAgo, "HH:mm"),
};
const updateTime = (hour: string, minute: string) => `${hour}:${minute}`;
const timePickerSlice = createSlice({
  name: "timePicker",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setStartHour: (state, action) => {
      const newHour = action.payload;
      const newStartTime = updateTime(newHour, state.startTime.slice(3, 5));
      if (
        new Date(`1970-01-01T${newStartTime}`) >
        new Date(`1970-01-01T${state.endTime}`)
      ) {
        state.endTime = newStartTime;
      }
      state.startTime = newStartTime;
    },
    setStartMin: (state, action) => {
      const newMinute = action.payload;
      const newStartTime = updateTime(state.startTime.slice(0, 2), newMinute);
      if (
        new Date(`1970-01-01T${newStartTime}`) >
        new Date(`1970-01-01T${state.endTime}`)
      ) {
        state.endTime = newStartTime;
      }
      state.startTime = newStartTime;
    },
    setEndHour: (state, action) => {
      const newHour = action.payload;
      const newEndTime = updateTime(newHour, state.endTime.slice(3, 5));
      if (
        new Date(`1970-01-01T${state.startTime}`) >
        new Date(`1970-01-01T${newEndTime}`)
      ) {
        state.startTime = newEndTime;
      }
      state.endTime = newEndTime;
    },
    setEndMin: (state, action) => {
      const newMinute = action.payload;
      const newEndTime = updateTime(state.endTime.slice(0, 2), newMinute);
      if (
        new Date(`1970-01-01T${state.startTime}`) >
        new Date(`1970-01-01T${newEndTime}`)
      ) {
        state.startTime = newEndTime;
      }
      state.endTime = newEndTime;
    },
  },
});

export default timePickerSlice.reducer;
export const { setOpen, setStartHour, setStartMin, setEndHour, setEndMin } =
  timePickerSlice.actions;
