import React, { useEffect, useState } from "react";
import { DetailTabContainer } from "../../../css/navibox/naviboxDetail/NaviboxDetailHeader.styles";
import {
  DetailTabBox,
  DetailTabMenu,
} from "../../../css/navibox/naviboxDetail/NaviboxDetailTabMenu.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setActive } from "../../../store/features/tabSlice";
import { useTranslation } from "react-i18next";

const NaviboxDetailTabMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeTab = useSelector((state: RootState) => state.tab.active);

  useEffect(() => {
    dispatch(setActive("TRAFFIC_TAB"));
  }, []);

  // 탭 클릭 핸들러
  const handleTabClick = (tabName: string) => dispatch(setActive(tabName));
  return (
    <DetailTabContainer>
      <DetailTabBox>
        {[
          "TRAFFIC_TAB",
          // "VIDEO_TAB",
          "IMAGE_TAB",
          "LOG_TAB",
          "CONTROL_TAB",
          "STATUS_TAB",
          "INFO_TAB",
        ].map((tabName) => (
          <DetailTabMenu
            key={tabName}
            $clicked={activeTab === tabName}
            onClick={() => handleTabClick(tabName)}
          >
            {t(tabName)}
          </DetailTabMenu>
        ))}
      </DetailTabBox>
    </DetailTabContainer>
  );
};

export default NaviboxDetailTabMenu;
