import React from "react";
import { ContainerTitle, TextBlue } from "../../css/dashboard/Dashboard.styles";
import { ReactComponent as IcVideo } from "../../asset/icons/ic_video.svg";
import { ReactComponent as IcImage } from "../../asset/icons/ic_image.svg";
import { ReactComponent as IcLog } from "../../asset/icons/ic_log.svg";
import {
  FileStatusBold,
  FileStatusContainer,
  FileStatusContent,
  FileStatusNormal,
  FileStatusRow,
} from "../../css/dashboard/FileStatus.styles";
import { useTranslation } from "react-i18next";

export interface IFileStatusProps {
  data: {
    device_id: number;
    image_num: number;
    log_num: number;
    video_num: number;
  };
}

export const initialFileStatusData = {
  device_id: 0,
  image_num: 0,
  log_num: 0,
  video_num: 0,
};
const FileStatus = ({ data }: IFileStatusProps) => {
  const { t } = useTranslation();
  return (
    <FileStatusContainer>
      <ContainerTitle>
        {t("FILE_TITLE")}
        <TextBlue> {t("FILE_SUB_TITLE")}</TextBlue>
      </ContainerTitle>
      <FileStatusRow>
        <FileStatusContent>
          <FileStatusNormal>{t("FILE_VIDEO")}</FileStatusNormal>
          <FileStatusBold>{data.video_num}</FileStatusBold>
          <IcVideo />
        </FileStatusContent>
        <FileStatusContent>
          <FileStatusNormal>{t("FILE_IMAGE")}</FileStatusNormal>
          <FileStatusBold>{data.image_num}</FileStatusBold>
          <IcImage />
        </FileStatusContent>
        <FileStatusContent>
          <FileStatusNormal>{t("FILE_LOG")}</FileStatusNormal>
          <FileStatusBold>{data.log_num}</FileStatusBold>
          <IcLog />
        </FileStatusContent>
      </FileStatusRow>
    </FileStatusContainer>
  );
};

export default FileStatus;
