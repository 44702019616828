import React from "react";
import {
  NotFoundContatiner,
  NotFoundContent,
  NotFoundTitle,
} from "../css/common/Common.styles";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <NotFoundContatiner>
      <NotFoundTitle>404 Error</NotFoundTitle>
      <NotFoundContent>
        <p>{t("404ERROR_MSG")}</p>
        <p onClick={() => navigate(-1)}>{t("404ERROR_GO_BACK")}</p>
      </NotFoundContent>
    </NotFoundContatiner>
  );
};

export default NotFound;
