import React from "react";
import {
  ContainerTitle,
  TextGray,
} from "../../../css/dashboard/Dashboard.styles";
import {
  TrafficChartContainer,
  TrafficContainer,
  TrafficRiskChartWrapper,
} from "../../../css/dashboard/TrafficChart.styles";
import Bar from "../../../util/chart/Bar";
import Line from "../../../util/chart/Line";
import { useTranslation } from "react-i18next";
interface NaviBoxData {
  date: string[];
  num: number[];
}

interface NaviBoxes {
  [key: string]: NaviBoxData;
}
export const initialTrafficRiskData = {
  NaviBox001: {
    date: [],
    num: [],
  },
};
const TrafficRisk = ({ data }: { data: NaviBoxes }) => {
  const { t } = useTranslation();

  const speedFirstKey = Object.keys(data)[0];
  const speedCategories = data[speedFirstKey]?.date.slice(-7);
  const transformData = (obj: NaviBoxes) => {
    return Object.entries(obj).map(([name, value]) => {
      return { name, data: value.num.slice(-7) };
    });
  };

  const riskData = transformData(data);
  return (
    <TrafficContainer>
      <ContainerTitle>
        {t("RISK_TITLE")}
        <TextGray>{t("RISK_SUB_TITLE")}</TextGray>
      </ContainerTitle>
      {riskData.length > 0 && (
        <Line
          data={riskData}
          categories={speedCategories}
          height={230}
          hasEvent={false}
        ></Line>
      )}
    </TrafficContainer>
  );
};

export default TrafficRisk;
