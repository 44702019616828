import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../../common/Common.styles";
import colors from "../../../global/colors";

export const TrafficAnalysisContainer = styled(FlexColumn)`
  width: 100%;
`;

export const TrafficFlexRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

export const DateTabContainer = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin: 10px 0;
`;

export const DateTabMenu = styled.li<{ $clicked: boolean }>`
  width: 185px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${(props) =>
    props.$clicked ? colors.btn.primary : colors.btn.dark};
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  color: ${(props) => (props.$clicked ? "#fff" : colors.text.secondary)};
  cursor: pointer;
  &:hover {
    background-color: ${colors.btn.darkHover};
  }
`;

export const TrafficChartBox = styled(FlexColumn)`
  width: 615px;
  height: 300px;
  padding: 30px 30px 0 30px;
  border-radius: 10px;
  background-color: #191b25;
`;

export const TrafficChartTitleWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  label {
    font-family: SUIT;
    font-size: 16px;
    font-weight: 500;
    color: #d7d7d7;
  }
`;

export const TrafficChart = styled.div`
  box-sizing: border-box;
  display: block;
`;
export const TrafficRiskBox = styled.div`
  min-width: 1300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  background-color: #191b25;
  margin-top: 10px;
`;

export const TrafficRiskChart = styled(TrafficChart)`
  height: 250px;
  width: 1300px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ChartWrapper = styled.div`
  position: relative;
`;

export const ChartAreaWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 15px;
    background-color: #191b25;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 6px;
    background-color: #5c617a;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b25;
  }
`;
export const TrafficVolumeRight = styled(TrafficChart)`
  height: 270px;
  min-width: 610px;
`;
export const BackButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const BackButton = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  margin-left: 15px;
  font-family: SUIT;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
`;

export const BtnActive = styled.div`
  visibility: visible;
`;
