import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  msg: "",
};

const toastSlice = createSlice({
  name: "timePicker",
  initialState,
  reducers: {
    setToastOpen: (state, action) => {
      state.open = action.payload;
    },
    setToastMsg: (state, action) => {
      state.msg = action.payload;
    },
  },
});

export default toastSlice.reducer;
export const { setToastOpen, setToastMsg } = toastSlice.actions;
