import React from "react";
import { LogContainer } from "../../../../../css/navibox/naviboxDetail/tabContent/LogTab.styles";

import LogContent from "./logContent/LogContent";
import LogCalendar from "./LogCalendar";

const LogTab = () => {
  return (
    <LogContainer>
      <LogContent />
      <LogCalendar />
    </LogContainer>
  );
};

export default LogTab;
