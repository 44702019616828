import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  deviceName: "",
  deviceConnect: false,
};

const naviboxSlice = createSlice({
  name: "navibox",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setDeviceName: (state, action) => {
      state.deviceName = action.payload;
    },
    setIsConnect: (state, action) => {
      state.deviceConnect = action.payload;
    },
  },
});

export default naviboxSlice.reducer;
export const { setId, setDeviceName, setIsConnect } = naviboxSlice.actions;
