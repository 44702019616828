import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
  }
  
 div{
   transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
   transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
   transition-duration: 150ms;
 }
 
 select {
   cursor: pointer;
 }
 
 option{
   cursor: pointer;
 }
  button {
    font-family: "SUIT";
    outline: none !important;
    border: none;
    background: transparent;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  button:hover {
    cursor: pointer;
  }

  iframe {
    border: none !important;
  }
  

  body, html {
    width: 100%;
    height: 100%;
    background-color: #0e0f16;
    margin: 0;
    font-family: "SUIT";
  }

  body::-webkit-scrollbar {
    width: 15px;
    background-color: #1f212c;
  }

  body::-webkit-scrollbar-thumb {
    width: 15px;
    border-radius: 10px;
    background-color: #5c617a;
  }

  body::-webkit-scrollbar-track {
    background-color: #1f212c;
  }

  a {
    text-decoration: none;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  li{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    
  }
  
  input{
    font-family: "SUIT";
  }

  /* reset --------------------------------------------- */


  h1, h2, h3, h4, h5, h6 {
    margin: 0px;
  }

  ul, li {
    margin: 0px;
    list-style-type: none;
  }

  input {
    outline: none;
    border: none;
  }

  textarea {
    outline: none;
    border: none;
  }

  textarea:focus, input:focus {
    border-color: transparent !important;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  input:focus:-moz-placeholder {
    color: transparent;
  }

  input:focus::-moz-placeholder {
    color: transparent;
  }

  input:focus:-ms-input-placeholder {
    color: transparent;
  }

  textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }

  textarea:focus:-moz-placeholder {
    color: transparent;
  }

  textarea:focus::-moz-placeholder {
    color: transparent;
  }

  textarea:focus:-ms-input-placeholder {
    color: transparent;
  }

  input::-webkit-input-placeholder {
    color: #999999;
  }

  input:-moz-placeholder {
    color: #999999;
  }

  input::-moz-placeholder {
    color: #999999;
  }

  input:-ms-input-placeholder {
    color: #999999;
  }

  textarea::-webkit-input-placeholder {
    color: #999999;
  }

  textarea:-moz-placeholder {
    color: #999999;
  }

  textarea::-moz-placeholder {
    color: #999999;
  }

  textarea:-ms-input-placeholder {
    color: #999999;
  }

`;

export default GlobalStyle;
