import React from "react";
import {
  StatusChartWrapper,
  StatusGPUCanvas,
  StatusGPUContainer,
  StatusGPUText,
  StatusGPUTitle,
  StatusGPUValue,
  StatusGPUWrapper,
  StatusTitleWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/StatusTab.styles";
import Radial from "../../../../../../util/chart/Radial";
import colors from "../../../../../../css/global/colors";
const Gpu = ({ data }: { data: number }) => {
  function getUseColor(value: number) {
    let color = colors.chart.green;

    if (value >= 80) {
      color = colors.chart.pink;
    } else if (value >= 60) {
      color = colors.chart.yellow;
    }

    return color;
  }

  return (
    <StatusGPUContainer>
      <StatusTitleWrapper>
        <label>GPU</label>
      </StatusTitleWrapper>
      <StatusGPUWrapper>
        <StatusChartWrapper>
          <StatusGPUCanvas>
            <Radial
              data={[data]}
              color={getUseColor(data)}
              valueShow={false}
              height={320}
            />
          </StatusGPUCanvas>
          <StatusGPUText>
            <StatusGPUValue>{data}%</StatusGPUValue>
            <StatusGPUTitle>Use_GPU</StatusGPUTitle>
          </StatusGPUText>
        </StatusChartWrapper>
      </StatusGPUWrapper>
    </StatusGPUContainer>
  );
};

export default Gpu;
