import React, { useEffect, useRef } from "react";
import {
  TimePickerBox,
  TimePickerTitle,
} from "../../css/util/TimePicker.styles";
import TimeScroll from "./TimeScroll";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setOpen } from "../../store/features/timePikcerSlice";
import { useTranslation } from "react-i18next";

const TimePicker = ({ opener }: { opener: React.Ref<HTMLElement | null> }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timepickerRef: React.RefObject<HTMLElement | null> = useRef(null);
  const timePickerOpen = useSelector(
    (state: RootState) => state.timePicker.open,
  );
  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0"),
  ).concat(Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0")));
  const mins = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0"),
  ).concat(Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0")));

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const openerElement =
        opener && "current" in opener ? opener.current : null;

      if (!openerElement || !timepickerRef.current) {
        return;
      }

      if (
        timePickerOpen &&
        !timepickerRef.current.contains(event.target as Node) &&
        !openerElement.contains(event.target as Node)
      ) {
        dispatch(setOpen(false));
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch]);
  return (
    <TimePickerBox ref={timepickerRef as React.RefObject<HTMLDivElement>}>
      <TimeScroll
        title={t("HOUR")}
        timeArr={hours}
        timeLength={24}
        type={"startHour"}
      />
      <TimeScroll
        title={t("MIN")}
        timeArr={mins}
        timeLength={60}
        type={"startMin"}
      />
      <TimePickerTitle>~</TimePickerTitle>
      <TimeScroll
        title={t("HOUR")}
        timeArr={hours}
        timeLength={24}
        type={"endHour"}
      />
      <TimeScroll
        title={t("MIN")}
        timeArr={mins}
        timeLength={60}
        type={"endMin"}
      />
    </TimePickerBox>
  );
};

export default React.memo(TimePicker);
