import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const today = format(new Date(), "yyyy. MM. dd.");
const month = format(new Date(), "yyyyMM");

const initialState = {
  open: false,
  month: month,
  selectedVideoDate: today,
  selectedImageDate: today,
  selectedLogDate: today,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setSelectedVideoDate: (state, action) => {
      state.selectedVideoDate = action.payload;
    },
    setSelectedImageDate: (state, action) => {
      state.selectedImageDate = action.payload;
    },
    setSelectedLogDate: (state, action) => {
      state.selectedLogDate = action.payload;
    },
  },
});

export default calendarSlice.reducer;
export const {
  setOpen,
  setMonth,
  setSelectedVideoDate,
  setSelectedImageDate,
  setSelectedLogDate,
} = calendarSlice.actions;
