import styled from "styled-components";
import colors from "../global/colors";
import { BlueBtn } from "../common/Common.styles";

export const UserHelpContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0e0f16;
`;

export const UserHelpBox = styled.div`
  width: 396px;
  height: 442px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 0px;
  border-radius: 10px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  background-color: ${colors.box.basic};
`;

export const TabContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${colors.tab.noneSelectedTab};
  color: ${colors.text.secondary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0;
  }
`;

export const Tab = styled.button<{ $active: boolean; tabtype: string }>`
  width: 100%;
  height: 100%;
  flex: 1;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.$active ? colors.box.basic : "transparent")};
  color: ${(props) => (props.$active ? "white" : "inherit")};
  border-top-left-radius: ${(props) =>
    props.$active && props.tabtype === "id" ? "10px" : "0"};
  border-top-right-radius: ${(props) =>
    props.$active && props.tabtype === "password" ? "10px" : "0"};
`;

export const TabContent = styled.div`
  display: none;
  &.current {
    display: block;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 50px;

  label {
    font-size: 14px;
    text-align: center;
    color: ${colors.text.info};
    white-space: pre-line;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: 50px;
  margin: 10px 50px;
  display: flex;
  flex-direction: row;
  input {
    width: 100%;
    flex-grow: 0;
    padding: 15px 100px 15px 55px;
    color: #fff;
    border: none;
    border-radius: 5px;
    background-color: ${colors.input.basic};

    &:focus {
      background-color: #141523;
      outline: none;
    }

    &:focus::-webkit-input-placeholder {
      color: #fff;
    }
  }
`;

export const KeyboardIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

export const SvgWrapper = styled.div`
  position: relative;
`;

export const BtnContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  margin: 30px 50px;
`;

export const RightBtn = styled(BlueBtn)`
  flex: 1;
`;

export const CancelBtn = styled.button`
  flex: 1;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${colors.btn.secondary};
  margin-right: 10px;
  &:hover {
    background-color: ${colors.btn.secondaryHover};
  }
  &:active {
    background-color: ${colors.btn.secondaryActive};
  }
`;
