import styled from "styled-components";
import { FlexRow } from "../../../common/Common.styles";
import colors from "../../../global/colors";

export const ControlContainer = styled(FlexRow)`
  width: 100%;
`;

export const ControlBtnContainer = styled.div`
  width: 820px;
  height: 714px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
  padding: 30px;
`;

export const ControlTitle = styled.label`
  font-family: SUIT;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #d7d7d7;
  margin-bottom: 30px;
`;

export const ControlBtnBox = styled(FlexRow)`
  margin: 30px 0 60px 10px;
`;

export const ControlBtn = styled.button`
  width: 148px;
  height: 84px;
  margin-left: 10px;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  white-space: pre-line;
  color: #fff;
  border: none;
  border-radius: 5px;
  background-color: ${colors.btn.darkGray};
  cursor: pointer;
  &:hover {
    background-color: ${colors.btn.darkGrayHover};
  }
  &:active {
    background-color: ${colors.btn.darkGrayActive};
  }
  &:disabled {
    background: ${colors.btn.unactive};
    color: ${colors.text.secondary};
    cursor: default;
  }
`;

export const ControlStatusContainer = styled.div<{ $active: boolean }>`
  width: 410px;
  height: 714px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #191b25;
  padding: 30px;
  margin-left: 10px;
  div > label {
    color: ${(props) => (props.$active ? "#d7d7d7" : colors.text.secondary)};
  }
`;

export const ControlStatusWrapper = styled.div`
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #262937;
  padding: 0 20px 0 10px;

  label {
    font-family: SUIT;
    font-size: 16px;
    font-weight: 600;
    color: #d7d7d7;
  }
`;

export const DisabledControl = styled.span`
  color: #5c617a !important;
`;
