import styled, { css } from "styled-components";
import colors from "../global/colors";
import { FlexColumn, FlexRow } from "../common/Common.styles";

export const UserAccountContainer = styled.div`
  position: relative;
  width: 1280px;
  height: 620px;
  padding: 40px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
`;

export const UserAccountBox = styled.form`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${colors.box.darkGray};

  input {
    flex: 1;
    height: 50px;
    padding: 0 20px;
    border: none;
    border-radius: 5px;
    background-color: ${colors.box.darkGray};
    outline: none;
    font-family: SUIT;
    font-size: 15px;
    font-weight: 600;
    color: #d7d7d7;

    &:disabled {
      color: ${colors.box.disabled};
    }
  }

  button {
    height: 50px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    background-color: #114ce4;
    font-family: SUIT;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: ${colors.btn.primaryHover};
    }
    &:active {
      background-color: ${colors.btn.primaryActive};
    }
  }
`;

export const PwModifyBtn = styled.button`
  width: 90px;
  margin-left: 10px;
}`;
export const UserAccountWrapper = styled(FlexRow)`
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid ${colors.box.darkGray};
`;

export const UserAccountTitle = styled.div`
  width: 150px;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.text.secondary};
  margin-top: 15px;
`;

export const UserAccountContentWrapper = styled.div`
  width: 640px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;

  input[name="user_phone_num"] {
    flex: 0;
    width: 160px;
  }
`;

export const Dash = styled.span`
  margin: 0 5px;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.text.secondary};
`;

export const UserAccountScreenRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;
export const UserAccountScreenContainer = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 20px;
`;

export const UserAccountScreenWrapper = styled.div<{ $active: boolean }>`
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${colors.box.darkGray};
  cursor: pointer;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.text.secondary};
  svg {
    fill: #36394a;
  }

  &:hover {
    background-color: ${colors.btn.darkGrayHover};
    svg {
      fill: ${colors.btn.RoyalBlueHover};
    }
  }

  ${(props) =>
    props.$active &&
    css`
      color: #d7d7d7;
      background-color: ${colors.btn.darkGray};
      cursor: default;
      svg {
        fill: ${colors.btn.RoyalBlue};
      }

      &:hover {
        background-color: ${colors.btn
          .darkGray}; // Active 상태의 background-color를 유지합니다.
        svg {
          fill: ${colors.btn.RoyalBlue}; // Active 상태의 svg 색상을 유지합니다.
        }
      }
    `}
`;

export const UserAccountScreenTemp = styled.div`
  width: 200px;
  height: 120px;
  padding: 30px 0;
  margin-bottom: 20px;
`;

export const UserAccountSubmitWrapper = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  button {
    width: 120px;
    height: 50px;
    &:hover {
      background-color: ${colors.btn.primaryHover};
    }
    &:active {
      background-color: ${colors.btn.primaryActive};
    }
  }
`;

export const Resign = styled.label`
  font-family: SUIT;
  font-size: 15px;
  font-weight: 600;
  color: ${colors.text.error};
  margin-left: 30px;
  cursor: pointer;
`;
