import styled, { css } from "styled-components";
import { FlexRow } from "../../../common/Common.styles";
import colors from "../../../global/colors";

export const ImageTabContainer = styled(FlexRow)`
  padding: 0 30px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const ImageTabButton = styled.button<{ $active: boolean }>`
  width: 160px;
  height: 50px;
  background-color: ${(props) =>
    props.$active ? colors.box.basic : colors.box.darkBox};
  font-family: SUIT;
  font-size: 14px;
  font-weight: bold;
  border: none;
  color: ${(props) => (props.$active ? "#fff" : colors.text.secondary)};
`;

export const ImageJaywalkingTab = styled(ImageTabButton)`
  border-radius: 5px 0 0 5px;
`;

export const ImageCaptrueTab = styled(ImageTabButton)`
  border-radius: 0 5px 5px 0;
`;
