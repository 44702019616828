import React, { useState } from "react";

import {
  format,
  startOfMonth,
  endOfMonth,
  differenceInDays,
  sub,
  add,
  setDate,
  getDate,
  parse,
} from "date-fns";
import { CalendarProps } from "../../interface/Calendar";
import {
  CalendarMonthChange,
  CalendarThisMonth,
  CalendarWeeks,
  Day,
  DayDotWrapper,
  GridWrapper,
  HasLogDot,
  PrefixDays,
} from "../../css/util/Calendar.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setMonth,
  setSelectedImageDate,
  setSelectedLogDate,
  setSelectedVideoDate,
} from "../../store/features/calendarSlice";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

/**
 *
 * @param field react-hook-form 데이터 전송 변수
 * @param inputLen input 길이 설정
 * @param isStart 시작 날짜 설정 달력인지 아닌지 구분
 * @returns {JSX.Element} input + 달력
 * @constructor
 */
const Calendar = ({
  field,
  inputLen,
  opener,
  type,
  eventDates = [],
}: CalendarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const today = new Date();
  const selectedDate = useSelector((state: RootState) => {
    if (type === "video") {
      return state.calendar.selectedVideoDate;
    } else if (type === "image") {
      return state.calendar.selectedImageDate;
    } else if (type === "log") {
      return state.calendar.selectedLogDate;
    } else {
      return format(new Date(), "yyyy. MM. dd.");
    }
  });

  const parseDate = parse(selectedDate, "yyyy. MM. dd.", new Date());

  const [value, setValue] = useState(parseDate);

  const onChange = (date: Date) => setValue(date);
  // 요일 배열
  const weeks = t("WEEK", { returnObjects: true });
  // 해당 달의 시작하는 날
  const startDate = startOfMonth(value);
  // 해당 달의 끝나는 날
  const endDate = endOfMonth(value);
  // 해당 달의 총 날 수
  const totalDays = differenceInDays(endDate, startDate) + 1;

  // 해당 달 시작 전 빈 칸 수
  const prefixDays = startDate.getDay();
  // 해당 달 종료 후 빈 칸 수
  const suffixDays = 6 - endDate.getDay();

  const prevMonth = () => {
    const prev = sub(value, { months: 1 });
    const month = format(prev, "yyyyMM");
    onChange(prev);
    dispatch(setMonth(month));
  };

  const nextMonth = () => {
    const next = add(value, { months: 1 });
    const month = format(next, "yyyyMM");
    onChange(next);
    dispatch(setMonth(month));
  };
  /**
   * 날짜 선택 함수
   * @param index 선택한 일자
   */
  const handleClickDate = (index: number) => {
    const date = setDate(value, index); // 선택한 일자를 해당 달의 Date 형태로 변경하고 value 값으로 설정

    if (type === "video") {
      dispatch(setSelectedVideoDate(format(date, "yyyy. MM. dd.")));
    } else if (type === "image") {
      dispatch(setSelectedImageDate(format(date, "yyyy. MM. dd.")));
    } else if (type === "log") {
      dispatch(setSelectedLogDate(format(date, "yyyy. MM. dd.")));
    }

    onChange(date); // 날짜 상태값 업데이트
  };

  return (
    <GridWrapper>
      <CalendarThisMonth>{format(value, t("YEARNMONTH"))}</CalendarThisMonth>
      <CalendarMonthChange>
        <button onClick={prevMonth}>{"<"}</button>
        <button style={{ marginLeft: "12px" }} onClick={nextMonth}>
          {">"}
        </button>
      </CalendarMonthChange>

      {Array.isArray(weeks) &&
        weeks.map((week) => <CalendarWeeks key={week}>{week}</CalendarWeeks>)}

      {Array.from({ length: prefixDays }).map((_, index) => (
        <PrefixDays key={index} />
      ))}
      {Array.from({ length: totalDays }).map((_, index) => {
        const date = index + 1;

        const isSelectedDate =
          date === getDate(parseDate) &&
          value.getMonth() === parseDate.getMonth() &&
          value.getFullYear() === parseDate.getFullYear();
        const isToday =
          date === getDate(today) &&
          value.getMonth() === today.getMonth() &&
          value.getFullYear() === today.getFullYear();

        const dayIndex = new Date(
          value.getFullYear(),
          value.getMonth(),
          date,
        ).getDay();
        return (
          <Day
            key={date}
            $isActive={isSelectedDate}
            onClick={() => handleClickDate(date)}
            $today={isToday}
            $dayIndex={dayIndex}
          >
            <DayDotWrapper>
              {date}
              <HasLogDot $isVisible={eventDates?.includes(date)} />
            </DayDotWrapper>
          </Day>
        );
      })}
      {Array.from({ length: suffixDays }).map((_, index) => (
        <PrefixDays key={index} />
      ))}
    </GridWrapper>
  );
};

export default Calendar;
