import React from "react";
import {
  StatusProgress,
  StatusProgressBox,
  StatusProgressFlexRow,
  StatusProgressValueWrapper,
  StatusProgressWrapper,
  StatusStorageContainer,
  StatusTitleWrapper,
} from "../../../../../../css/navibox/naviboxDetail/tabContent/StatusTab.styles";
import colors from "../../../../../../css/global/colors";

const Storage = ({ data }: { data: number }) => {
  function getUseColor(value: number) {
    let color = colors.chart.green;

    if (value >= 80) {
      color = colors.chart.pink;
    } else if (value >= 60) {
      color = colors.chart.yellow;
    }

    return color;
  }
  return (
    <StatusStorageContainer>
      <StatusTitleWrapper>
        <label>Storage</label>
      </StatusTitleWrapper>
      <StatusProgressFlexRow>
        <StatusProgressValueWrapper>{data}%</StatusProgressValueWrapper>
        <StatusProgressBox>
          <StatusProgressWrapper>
            <StatusProgress $bgcolor={getUseColor(data)} value={data} />
          </StatusProgressWrapper>
        </StatusProgressBox>
      </StatusProgressFlexRow>
    </StatusStorageContainer>
  );
};

export default Storage;
