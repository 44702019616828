import React from "react";
import { ControlContainer } from "../../../../../css/navibox/naviboxDetail/tabContent/ControlTab.styles";
import ControlBtns from "./ControlBtns";
import ControlStatus from "./ControlStatus";

const ControlTab = () => {
  return (
    <ControlContainer>
      <ControlBtns />
      <ControlStatus />
    </ControlContainer>
  );
};

export default ControlTab;
