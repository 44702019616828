import React, { SetStateAction, useEffect, useState } from "react";

import { ReactComponent as IcPower } from "../../../asset/icons/ic_power.svg";
import { ReactComponent as IcCapture } from "../../../asset/icons/ic_capture.svg";
import { ReactComponent as IcFolder } from "../../../asset/icons/ic_image.svg";
import {
  CameraButton,
  CameraSwitchWrapper,
  CaptureButtonTitle,
  CaptureButtonWrapper,
  CaptureContainer,
  CaptureFolderWrapper,
  DetailHeader,
  DetailNameWrapper,
} from "../../../css/navibox/naviboxDetail/NaviboxDetailHeader.styles";
import { useDispatch } from "react-redux";
import { setActive } from "../../../store/features/tabSlice";
import { useTranslation } from "react-i18next";
import { IIsPending } from "../../../page/NaviboxList";
import { DataApi } from "../../../api/DataApi";
import { useMutation } from "@tanstack/react-query";
const NaviboxDetailHeader = ({
  deviceName,
  isConnected,
  camera,
  setCamera,
  setIsPending,
  isPending,
  disabled,
}: {
  deviceName: string;
  isConnected: boolean;
  camera: number;
  setCamera: React.Dispatch<SetStateAction<number>>;
  setIsPending: React.Dispatch<SetStateAction<IIsPending>>;
  disabled: boolean;
  isPending: IIsPending;
}) => {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mutation = useMutation<
    string,
    string,
    { deviceName: string; camera: number }
  >({
    mutationFn: ({ deviceName, camera }) =>
      DataApi.naviboxCapture(deviceName, camera),
    onSuccess: (data: string) => {
      setIsPending({ deviceName: "", status: false });
    },
  });

  const handleCamera = (num: number) => setCamera(num);
  const handleCapture = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!isConnected && mutation.isPending) return;
    event.stopPropagation();
    mutation.mutate({ deviceName, camera });
  };

  useEffect(() => {
    mutation.isPending &&
      setIsPending({ deviceName: deviceName, status: true });
  }, [mutation.isPending]);
  const handleScroll = () => {
    dispatch(setActive("IMAGE_TAB"));
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  return (
    <DetailHeader>
      <DetailNameWrapper $active={isConnected}>
        <label>{deviceName}</label>
        <IcPower />
      </DetailNameWrapper>
      <CaptureContainer>
        <CameraSwitchWrapper>
          <CameraButton
            $clicked={camera === 1}
            $active={isConnected}
            onClick={() => handleCamera(1)}
          >
            1
          </CameraButton>
          <CameraButton
            $clicked={camera === 2}
            $active={isConnected}
            onClick={() => handleCamera(2)}
          >
            2
          </CameraButton>
        </CameraSwitchWrapper>
        <CaptureButtonWrapper
          $active={isConnected}
          onClick={(event) => handleCapture(event)}
        >
          <CaptureButtonTitle>{t("DETAIL_CAMERA_TITLE")}</CaptureButtonTitle>
          <IcCapture />
        </CaptureButtonWrapper>
        <CaptureFolderWrapper
          $active={isConnected}
          onClick={() => handleScroll()}
        >
          <IcFolder />
        </CaptureFolderWrapper>
      </CaptureContainer>
    </DetailHeader>
  );
};

export default NaviboxDetailHeader;
