import React, { SetStateAction, useEffect } from "react";
import { FlexColumn } from "../../../css/common/Common.styles";
import {
  NaviboxCaptureButtonTitle,
  NaviboxCaptureButtonWrapper,
} from "../../../css/navibox/naviboxList/NaviboxList.styles";
import { ReactComponent as IcCapture } from "../../../asset/icons/ic_capture.svg";
import { ReactComponent as IcInactiveCapture } from "../../../asset/icons/ic_inactive_capture.svg";
import { useTranslation } from "react-i18next";
import { DataApi } from "../../../api/DataApi";
import { useMutation } from "@tanstack/react-query";
import { IIsPending } from "../../../page/NaviboxList";

const CaptureButtons = ({
  $active,
  device_name,
  setIsPending,
  isPending,
  disabled,
}: {
  $active: boolean;
  device_name: string;
  setIsPending: React.Dispatch<SetStateAction<IIsPending>>;
  disabled: boolean;
  isPending: IIsPending;
}) => {
  const { t } = useTranslation();

  const mutation = useMutation<string, string, string>({
    mutationFn: (device_name) => DataApi.naviboxCapture(device_name),
    onSuccess: (data: string) => {
      setIsPending({ deviceName: "", status: false });
    },
  });

  const handleCapture = (event: React.MouseEvent) => {
    if (!$active || mutation.isPending) return;
    event.stopPropagation();
    mutation.mutate(device_name);
  };

  useEffect(() => {
    mutation.isPending &&
      setIsPending({ deviceName: device_name, status: true });
  }, [mutation.isPending]);

  return (
    <NaviboxCaptureButtonWrapper
      $active={$active || !isPending}
      onClick={(event) => handleCapture(event)}
    >
      <FlexColumn>
        <NaviboxCaptureButtonTitle>
          {t("CAMERA_TITLE")}
        </NaviboxCaptureButtonTitle>
      </FlexColumn>
      {$active ? <IcCapture /> : <IcInactiveCapture />}
    </NaviboxCaptureButtonWrapper>
  );
};

export default CaptureButtons;
