import React, { useState } from "react";
import {
  ControlBtn,
  ControlBtnBox,
  ControlBtnContainer,
  ControlTitle,
} from "../../../../../css/navibox/naviboxDetail/tabContent/ControlTab.styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useMutation } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
export interface INaviboxControl {
  device_name: string;
  method: string;
  payload?: string;
  button_name?: string;
}
const ControlBtns = () => {
  const device_name = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );
  const isConnected = useSelector(
    (state: RootState) => state.navibox.deviceConnect,
  );
  const mutation = useMutation({
    mutationFn: (params: INaviboxControl) => DataApi.naviboxControl(params),
  });
  const handleClick = (
    method: string,
    payload?: string,
    button_name?: string,
  ) => {
    if (mutation.isPending) return;

    mutation.mutate({ device_name, method, payload, button_name });
  };
  const { t } = useTranslation();
  return (
    <ControlBtnContainer>
      <ControlTitle>{t("NAVIBOX_TITLE")}</ControlTitle>
      <ControlBtnBox>
        {[
          {
            method: "record",
            title: "Start Recording",
          },
          {
            method: "algorithm",
            title: "Stop Algorithm",
          },
          {
            method: "capture",
            title: "Capture Camera",
          },
          {
            method: "reboot",
            title: "Reboot",
          },
        ].map((btn, index) => (
          <ControlBtn
            key={index}
            onClick={() => handleClick(btn.method, "", btn.title)}
            disabled={!isConnected}
          >
            {t(btn.title)}
          </ControlBtn>
        ))}
      </ControlBtnBox>
      <ControlTitle>{t("PANEL_TITLE")}</ControlTitle>
      <ControlBtnBox>
        {[
          "Do Not Display",
          "Warning Pedestrian",
          "Warning Collision",
          "Dangerous Pedestrian",
        ].map((btn, index) => (
          <ControlBtn
            key={index}
            onClick={() => handleClick("board", index + 1 + "", btn)}
            disabled={!isConnected}
          >
            {t(btn)}
          </ControlBtn>
        ))}
      </ControlBtnBox>
      <ControlTitle>{t("DO_NOT_DISPLAY_2")}</ControlTitle>
      <ControlBtnBox>
        {["Status", "Turn Off Smartphone", "No Jay Walking", "Warning Car"].map(
          (btn, index) => (
            <ControlBtn
              key={index}
              onClick={() => handleClick("speaker", index + 1 + "", btn)}
              disabled={!isConnected}
            >
              {t(btn)}
            </ControlBtn>
          ),
        )}
      </ControlBtnBox>
    </ControlBtnContainer>
  );
};

export default ControlBtns;
