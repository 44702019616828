import styled, { keyframes } from "styled-components";
import { FlexRow } from "../common/Common.styles";

export const rotate = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 600px;
`;

export const SpinnerBox = styled(FlexRow)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const SpinnerOuter = styled.div<{ $size: number }>`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  padding: ${(props) => (props.$size === 70 ? "5px" : "0.8%")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #114ce4;
  background: linear-gradient(0deg, transparent 33%, #114ce4 100%);
  animation: ${rotate} 0.6s linear 0s infinite;
`;

export const SpinnerCore = styled.div<{ $bgColor: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.$bgColor};
  border-radius: 50%;
`;
export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
`;
