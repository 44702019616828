import styled from "styled-components";
import colors from "../global/colors";

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 1360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const BlueBtn = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${colors.btn.primary};
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${colors.btn.primaryHover};
  }
  &:active {
    background-color: ${colors.btn.primaryActive};
  }
`;
export const NotFoundContatiner = styled(FlexColumn)`
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NotFoundTitle = styled.div`
  color: #fff;
  font-family: SUIT;
  font-weight: bold;
  font-size: 80px;
`;

export const NotFoundContent = styled(FlexColumn)`
  margin-top: 23px;
  align-items: center;
  white-space: pre-line;
  p {
    text-align: center;
    font-size: 15px;
    line-height: 28px;
    color: ${colors.text.info};
  }
  p:last-child {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: ${colors.text.blue};
    cursor: pointer;
  }
`;
