import React, { useEffect, useState } from "react";
import { Container, Content } from "../css/common/Common.styles";
import { VideoWrapper } from "../css/navibox/naviboxDetail/NaviboxDetailHeader.styles";
import NaviboxDetailHeader from "../content/navibox/naviboxDetail/NaviboxDetailHeader";
import { DetailTabContentContainer } from "../css/navibox/naviboxList/NaviboxDetail.styles";
import NaviboxDetailTabMenu from "../content/navibox/naviboxDetail/NaviboxDetailTabMenu";
import StatusTab from "../content/navibox/naviboxDetail/tabContent/statusTab/StatusTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import ControlTab from "../content/navibox/naviboxDetail/tabContent/controlTab/ControlTab";
import TrafficTab from "../content/navibox/naviboxDetail/tabContent/trafficTab/TrafficTab";
import VideoTab from "../content/navibox/naviboxDetail/tabContent/videoTab/VideoTab";
import ImageTab from "../content/navibox/naviboxDetail/tabContent/imageTab/ImageTab";
import LogTab from "../content/navibox/naviboxDetail/tabContent/logTab/LogTab";
import DeviceTab from "../content/navibox/naviboxDetail/tabContent/deviceTab/DeviceTab";
import { useLocation, useParams } from "react-router-dom";
import {
  setDeviceName,
  setId,
  setIsConnect,
} from "../store/features/naviboxSlice";
import NaviboxStream from "../content/navibox/naviboxList/NaviboxStream";
import Spinner from "../util/Spinner";

const NaviboxDetail = () => {
  const activeTab = useSelector((state: RootState) => state.tab.active);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = useParams().id || "1";
  const deviceName = location.state?.deviceName;
  const isConnected = location.state?.isConnected;
  const [camera, setCamera] = useState(1);
  const [isPending, setIsPending] = useState({ deviceName: "", status: false });

  useEffect(() => {
    dispatch(setId(id));
    dispatch(setDeviceName(deviceName));
    dispatch(setIsConnect(isConnected));
  }, [id]);

  return (
    <Content>
      <Container>
        <NaviboxDetailHeader
          deviceName={deviceName}
          isConnected={isConnected}
          camera={camera}
          setCamera={setCamera}
          setIsPending={setIsPending}
          isPending={isPending}
          disabled={isPending.deviceName === deviceName && isPending.status}
        />
        {isConnected ? (
          isPending.status ? (
            <VideoWrapper>
              <Spinner size={70} bgColor={"#0e1016"} />
            </VideoWrapper>
          ) : (
            <VideoWrapper>
              <NaviboxStream
                is_connected={Boolean(isConnected)}
                deviceId={Number(id)}
                deviceName={deviceName}
                camera={camera}
              />
            </VideoWrapper>
          )
        ) : (
          <VideoWrapper>
            {/*<Video />*/}
            <canvas />
          </VideoWrapper>
        )}
        <NaviboxDetailTabMenu />
        <DetailTabContentContainer>
          {activeTab === "STATUS_TAB" && <StatusTab />}
          {activeTab === "CONTROL_TAB" && <ControlTab />}
          {activeTab === "TRAFFIC_TAB" && <TrafficTab />}
          {/*{activeTab === "VIDEO_TAB" && <VideoTab />}*/}
          {activeTab === "IMAGE_TAB" && <ImageTab />}
          {activeTab === "LOG_TAB" && <LogTab />}
          {activeTab === "INFO_TAB" && <DeviceTab />}
        </DetailTabContentContainer>
      </Container>
    </Content>
  );
};

export default NaviboxDetail;
