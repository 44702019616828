import React from "react";
import {
  FlexRowDivision,
  Video9,
  VideoContainerDivision,
  VideoWrapper,
} from "../css/navibox/naviboxDetail/tabContent/VideoTab.styles";
import { FlexRow } from "../css/common/Common.styles";

const Division9 = () => {
  return (
    <VideoContainerDivision>
      <FlexRowDivision>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
        <Video9>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video9>
      </FlexRowDivision>
    </VideoContainerDivision>
  );
};

export default Division9;
