import styled from "styled-components";
import colors from "./colors";

export const ToastWrapper = styled.div`
  position: absolute;
  padding: 18px;
  overflow: hidden;
  color: #fff;
  z-index: 10000;
  border-radius: 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: ${colors.box.basic};
  opacity: 0;
  svg {
    width: 14px;
    height: 9.6px;
    margin-right: 10px;
  }
`;

export const ToastMessage = styled.label`
  font-family: SUIT;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: ${colors.text.green};
`;
