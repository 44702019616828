import colors from "../css/global/colors";

export function getTempColor(value: number) {
  let color = colors.chart.green;

  if (value >= 70) {
    color = colors.chart.pink;
  } else if (value >= 60) {
    color = colors.chart.yellow;
  }

  return color;
}
