import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ContnetWarpper,
  Dot,
  Header,
  HeaderContainer,
  HeaderLanguage,
  HeaderLogoutWrapper,
  HeaderLogoWrapper,
  HeaderMenuContainer,
  HeaderRightContainer,
  LanguageWrapper,
  MenuName,
} from "../css/layout/Layout.styles";

import { ReactComponent as IcLogout } from "../asset/icons/ic_logout.svg";
import { ReactComponent as IcLogoHeader } from "../asset/icons/ic_logo_header.svg";
import { useTranslation } from "react-i18next";
import i18next from "../lang/ i18n";
const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const startScreen = localStorage.getItem("startScreen");
    if (!accessToken || !refreshToken) {
      navigate("/login", { replace: true });
    } else if (location.pathname === "/") {
      navigate(`/${startScreen}`);
    }
  }, []);

  const checkActive = (path: string) => location.pathname.includes(path);
  const clickHandler = (lang: string) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  const savedLang = localStorage.getItem("lang");
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/login", { replace: true });
  };
  return (
    <>
      <Header>
        <HeaderContainer>
          <HeaderLogoWrapper onClick={() => navigate("/dashboard")}>
            <IcLogoHeader />
          </HeaderLogoWrapper>
          <HeaderMenuContainer>
            <ul>
              <li>
                <MenuName to="/dashboard" $active={checkActive("dashboard")}>
                  {t("MENU_DASHBOARD")}
                </MenuName>
              </li>
              <li>
                <MenuName to="/navibox" $active={checkActive("navibox")}>
                  {t("MENU_NAVIBOX")}
                </MenuName>
              </li>
              <li>
                <MenuName to="/account" $active={checkActive("account")}>
                  {t("MENU_ACCOUNT")}
                </MenuName>
              </li>
            </ul>
          </HeaderMenuContainer>

          <HeaderRightContainer>
            <LanguageWrapper>
              <HeaderLanguage
                onClick={() => clickHandler("ko")}
                $active={savedLang === "ko"}
              >
                KR
              </HeaderLanguage>
              <Dot />
              <HeaderLanguage
                onClick={() => clickHandler("en")}
                $active={savedLang === "en"}
              >
                EN
              </HeaderLanguage>
            </LanguageWrapper>

            <HeaderLogoutWrapper onClick={() => handleLogout()}>
              <IcLogout />
            </HeaderLogoutWrapper>
          </HeaderRightContainer>
        </HeaderContainer>
      </Header>
      <ContnetWarpper>
        <Outlet />
      </ContnetWarpper>
    </>
  );
};

export default Layout;
