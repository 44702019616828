import axios from "axios";
import { ILoginProps } from "../page/Login";

export const LoginApi = {
  login: async (params: ILoginProps) => {
    const { id, password } = params;
    const response = await axios.post(
      `/login/user`,
      {
        username: id,
        password,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    );
    const data = response.data;
    localStorage.setItem("accessToken", data["access_token"]);
    localStorage.setItem("refreshToken", data["refresh_token"]);
    localStorage.setItem("startScreen", data["start_screen"]);
    const res = goScreen(data["start_screen"]);
    function goScreen(screen: string) {
      switch (screen) {
        case "division_16":
          return "/video/16";

        case "division_9":
          return "/video/9";

        case "division_4":
          return "/video/4";

        case "list":
          return "/navibox";
        default:
          return "/dashboard";
      }
    }
    return res;
  },
  findId: async (params: ILoginProps) => {
    const { company, phone_num } = params;
    const response = await axios.post(`/user/help/id`, {
      company,
      phone_num,
    });
    const data = response.data;
    return "success";
  },
  findPw: async (params: ILoginProps) => {},
};
