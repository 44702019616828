import React, { useEffect, useRef, useState } from "react";
import {
  VideoBox,
  VideoContainer,
  VideoDateContainer,
  VideoDateWrapper,
  VideoListContainer,
  VideoModalBackground,
  VideoWrapper,
  ViedoDateSearchBtn,
} from "../../../../../css/navibox/naviboxDetail/tabContent/VideoTab.styles";
import { ReactComponent as IcCalendar } from "../../../../../asset/icons/ic_calendar.svg";

import ImageSearchRes from "./ImageSearchRes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { setOpen } from "../../../../../store/features/calendarSlice";
import SmCalendar from "../../../../../util/calendar/SmCalendar";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";

const ImageTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarOpen = useSelector((state: RootState) => state.calendar.open);
  const selectedDay = useSelector(
    (state: RootState) => state.calendar.selectedImageDate,
  );
  const id = useSelector((state: RootState) => state.navibox.id);
  const date = useSelector((state: RootState) => state.calendar.month);
  const ref = useRef<HTMLDivElement>(null);
  const [eventDates, setEventDates] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const azureBaseURL = process.env.REACT_APP_AZURE_BASE_URL;
  const deviceName = useSelector(
    (state: RootState) => state.navibox.deviceName,
  );
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxImageEvnetDates", id, date],
    queryFn: () => DataApi.naviboxImageEvnetDates(id, date),
  });
  useEffect(() => {
    if (isSuccess) {
      setEventDates(data);
    }
  }, [data]);

  return (
    <VideoContainer>
      <VideoModalBackground></VideoModalBackground>
      <VideoBox>
        <VideoWrapper>
          {imageLink.length > 0 ? (
            <img
              src={`${azureBaseURL}/capture/${imageLink}`}
              alt={"snapshot Image"}
            />
          ) : (
            <img src="/asset/images/black_screen.png" alt={"snapshot Image"} />
          )}
        </VideoWrapper>
      </VideoBox>
      <VideoListContainer>
        <VideoDateContainer>
          <VideoDateWrapper
            onClick={() => dispatch(setOpen(!calendarOpen))}
            ref={ref}
          >
            <IcCalendar />
            <label>{selectedDay}</label>
          </VideoDateWrapper>
          {calendarOpen && (
            <SmCalendar opener={ref} type={"image"} eventDates={eventDates} />
          )}
          <ViedoDateSearchBtn>{t("SEARCH")}</ViedoDateSearchBtn>
        </VideoDateContainer>
        <ImageSearchRes setImageLink={setImageLink} />
      </VideoListContainer>
    </VideoContainer>
  );
};

export default ImageTab;
