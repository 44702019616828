import React from "react";
import {} from "../../../css/dashboard/Dashboard.styles";
import {
  SpeedChart,
  TrafficChartContainer,
  VolumeChart,
} from "../../../css/dashboard/TrafficChart.styles";
import Bar from "../../../util/chart/Bar";
import { useTranslation } from "react-i18next";
interface NaviBoxData {
  date: string[];
  num: number[];
}

interface NaviBoxes {
  [key: string]: NaviBoxData;
}

export interface ITrafficVolumeSpeedProps {
  data: {
    avg_speed_top_3: NaviBoxes;
    traffic_volume_top3: NaviBoxes;
  };
}

export const initialTrafficVolumeSpeedData = {
  avg_speed_top_3: {},
  traffic_volume_top3: {},
};
const TrafficVolumeSpeed = ({ data }: ITrafficVolumeSpeedProps) => {
  const { t } = useTranslation();
  const volumeFirstKey = Object.keys(data.traffic_volume_top3)[0];
  const volumeCategories =
    data.traffic_volume_top3[volumeFirstKey]?.date.slice(-7);
  const speedFirstKey = Object.keys(data.avg_speed_top_3)[0];
  const speedCategories = data.avg_speed_top_3[speedFirstKey]?.date.slice(-7);
  const transformData = (obj: NaviBoxes) => {
    return Object.entries(obj).map(([name, value], index) => {
      return { name, data: value.num.slice(-7) };
    });
  };

  const avgSpeedData = transformData(data.avg_speed_top_3);
  const trafficVolumeData = transformData(data.traffic_volume_top3);
  return (
    <TrafficChartContainer>
      {trafficVolumeData.length > 0 && (
        <VolumeChart>
          <Bar
            data={trafficVolumeData}
            title={t("TRAFFIC_VOLUME")}
            categories={volumeCategories}
          ></Bar>
        </VolumeChart>
      )}
      <SpeedChart>
        {avgSpeedData.length > 0 && (
          <Bar
            data={avgSpeedData}
            title={t("TRAFFIC_SPEED")}
            categories={speedCategories}
          ></Bar>
        )}
      </SpeedChart>
    </TrafficChartContainer>
  );
};

export default TrafficVolumeSpeed;
