import React, { useState } from "react";
import {
  InputContainer,
  LoginBox,
  LoginButtonWrapper,
  LoginContainer,
  LoginErrorWrapper,
  LoginIconWrapper,
  LogoWrapper,
  UserHelpWrapper,
} from "../css/login/Login.styles";
import { ReactComponent as IcLogo } from "../asset/icons/ic_logo.svg";
import { ReactComponent as IcUser } from "../asset/icons/ic_user.svg";
import { ReactComponent as IcPassword } from "../asset/icons/ic_password.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginApi } from "../api/LoginApi";
import { useMutation } from "@tanstack/react-query";
export interface ILoginProps {
  id?: string;
  password?: string;
  company?: string;
  phone_num?: string;
}

const Login = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ILoginProps>();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const mutation = useMutation<string, string, ILoginProps>({
    mutationFn: (param: ILoginProps) => LoginApi.login(param),
    onSuccess: (data) => {
      navigate(data, { replace: true });
    },
    onError: () => setError(true),
  });

  const onValid: SubmitHandler<ILoginProps> = (data: ILoginProps) => {
    mutation.mutate(data);
  };

  return (
    <LoginContainer>
      <LogoWrapper>
        <IcLogo />
      </LogoWrapper>

      <LoginBox onSubmit={handleSubmit(onValid)}>
        <InputContainer>
          <LoginIconWrapper>
            <IcUser />
          </LoginIconWrapper>
          <input
            placeholder={t("ID_PLACEHOLDER")}
            type="text"
            required
            {...register("id", {
              required: "",
            })}
          />
        </InputContainer>
        <InputContainer>
          <LoginIconWrapper>
            <IcPassword />
          </LoginIconWrapper>
          <input
            placeholder={t("PW_PLACEHOLDER")}
            type="password"
            required
            {...register("password", {
              required: "",
            })}
          />
        </InputContainer>
        <LoginButtonWrapper>
          <button type="submit">{t("LOGIN_BTN")}</button>
        </LoginButtonWrapper>
      </LoginBox>

      <UserHelpWrapper>
        <Link to={{ pathname: "/find-account" }}>{t("FIND_ID_PW")}</Link>
      </UserHelpWrapper>
      <LoginErrorWrapper>
        {error && (
          <>
            <label>{t("LOGIN_ERROR_1")}</label>
            <label>{t("LOGIN_ERROR_2")}</label>
          </>
        )}
      </LoginErrorWrapper>
    </LoginContainer>
  );
};

export default Login;
