import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import store from "./store/store";
import { Provider } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use((config: any) => {
  const excludeUrls = ["/login/user", "/user/help/id"];

  if (!excludeUrls.includes(config.url!)) {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
      const navigate = useNavigate();
      navigate("/login", { replace: true });
      return Promise.reject("No tokens available");
    }
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.RefreshToken = `Bearer ${refreshToken}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const excludeUrls = ["/login/user", "/user/help/id"];
    // 에러 발생 시 URL 체크하여 특정 로직 실행
    if (!excludeUrls.includes(error.config.url)) {
      if (error.response?.status === 401) {
        // 401 Unauthorized 처리 로직
      } else if (error.response?.status === 403) {
        // 403 Forbidden 처리 로직
        const navigate = useNavigate();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login", { replace: true });
      }
    }
    return Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
