import React from "react";
import {
  FlexRowDivision,
  Video4,
  VideoContainerDivision,
} from "../css/navibox/naviboxDetail/tabContent/VideoTab.styles";

const Division4 = () => {
  return (
    <VideoContainerDivision>
      <FlexRowDivision>
        <Video4>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video4>
        <Video4>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video4>
      </FlexRowDivision>
      <FlexRowDivision>
        <Video4>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video4>
        <Video4>
          <video poster="/asset/images/black_screen.png" autoPlay={true} />
        </Video4>
      </FlexRowDivision>
    </VideoContainerDivision>
  );
};

export default Division4;
