import React, { useEffect, useState } from "react";
import {
  LogCalendarDecriptionBox,
  LogCalendarDescription,
  LogCalendarDescriptionDotWrapper,
  LogDateContainer,
  LogDot,
  SelectedDot,
  TodayDot,
} from "../../../../../css/navibox/naviboxDetail/tabContent/LogTab.styles";
import Calendar from "../../../../../util/calendar/Calendar";
import { LCalendarWrapper } from "../../../../../css/util/Calendar.styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../../../../api/DataApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

const LogCalendar = () => {
  const id = useSelector((state: RootState) => state.navibox.id);
  const date = useSelector((state: RootState) => state.calendar.month);
  const [eventDates, setEventDates] = useState([]);
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxLogEvnetDates", id, date],
    queryFn: () => DataApi.naviboxLogEvnetDates(id, date),
  });
  useEffect(() => {
    if (isSuccess) {
      setEventDates(data);
    }
  }, [data]);
  const { t } = useTranslation();
  return (
    <LogDateContainer>
      <LCalendarWrapper>
        <Calendar type={"log"} eventDates={eventDates} />
      </LCalendarWrapper>
      <LogCalendarDescription>
        <LogCalendarDecriptionBox>
          <LogCalendarDescriptionDotWrapper>
            <LogDot></LogDot>
          </LogCalendarDescriptionDotWrapper>
          <label>{t("LOG_EVENT")}</label>
        </LogCalendarDecriptionBox>
        <LogCalendarDecriptionBox>
          <LogCalendarDescriptionDotWrapper>
            <SelectedDot></SelectedDot>
          </LogCalendarDescriptionDotWrapper>
          <label>{t("CHOICE")}</label>
        </LogCalendarDecriptionBox>
        <LogCalendarDecriptionBox>
          <LogCalendarDescriptionDotWrapper>
            <TodayDot></TodayDot>
          </LogCalendarDescriptionDotWrapper>
          <label>{t("TODAY")}</label>
        </LogCalendarDecriptionBox>
      </LogCalendarDescription>
    </LogDateContainer>
  );
};

export default LogCalendar;
