import React from "react";
import {
  PasswordModalCancel,
  PasswordModalInputWrapper,
  PasswordModalSubmit,
  PasswordModalSubmitWrapper,
  PasswordModalWrapper,
} from "../../css/account/Modal.styles";
import { ModalErrorWrapper } from "../../css/util/Modal.styles";
import { setModalOpen } from "../../store/features/modalSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { DataApi } from "../../api/DataApi";
import { setToastMsg, setToastOpen } from "../../store/features/toastSlice";

export interface IUpdatePassword {
  password: string;
  new_password?: string;
  check_password?: string;
}
const PasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IUpdatePassword>();
  const mutation = useMutation<string, string, IUpdatePassword>({
    mutationFn: (param: IUpdatePassword) => DataApi.updatePassword(param),
    onSuccess: (data) => {
      Boolean(data)
        ? dispatch(setToastOpen(true))
        : setError("password", { message: t("NO_MATCH_CURRENT_PASSWORD") });
      setTimeout(() => dispatch(setModalOpen(false)), 1000);
    },
    onError: () =>
      setError("password", { message: t("NO_MATCH_CURRENT_PASSWORD") }),
  });
  const onValid = (data: IUpdatePassword) => {
    if (mutation.isPending) return;
    dispatch(setToastMsg(t("COMPLETE_MODIFY")));
    if (data.new_password !== data.check_password) {
      setError("check_password", { message: t("NO_MATCH_NEW_PASSWORD") });
    } else {
      mutation.mutate(data);
    }
  };
  return (
    <PasswordModalWrapper onSubmit={handleSubmit(onValid)}>
      <PasswordModalInputWrapper>
        <input
          type="password"
          placeholder={t("CURRENT_PASSWORD")}
          {...register("password", { required: true })}
        />
      </PasswordModalInputWrapper>
      <PasswordModalInputWrapper>
        <input
          type="password"
          placeholder={t("NEW_PASSWORD")}
          {...register("new_password", { required: true })}
        />
      </PasswordModalInputWrapper>
      <PasswordModalInputWrapper>
        <input
          type="password"
          placeholder={t("NEW_PASSWORD_CONFIRM")}
          {...register("check_password", { required: true })}
        />
      </PasswordModalInputWrapper>
      <ModalErrorWrapper>
        <label>
          {errors.password?.message || errors.check_password?.message}
        </label>
      </ModalErrorWrapper>
      <PasswordModalSubmitWrapper>
        <PasswordModalCancel
          type={"button"}
          onClick={() => dispatch(setModalOpen(false))}
        >
          {t("CANCEL")}
        </PasswordModalCancel>
        <PasswordModalSubmit>{t("CHANGE")}</PasswordModalSubmit>
      </PasswordModalSubmitWrapper>
    </PasswordModalWrapper>
  );
};

export default PasswordModal;
