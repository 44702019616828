const colors = {
  btn: {
    primary: "#114ce4",
    primaryHover: "#2771e7",
    primaryActive: "#0a3cb5",
    secondary: "#3e4263",
    secondaryHover: "#5a6080",
    secondaryActive: "#2e324a",
    darkGray: "#262c4f",
    darkGrayHover: "#3b4a6b",
    darkGrayActive: "#1c2333",
    unactive: "#262937",
    lightBlueActive: "#465acc",
    lightBlueHover: "#3e4263",
    ligthBlueUnactive: "#262c4f",
    dark: "#191b25",
    darkHover: "#273c75",
    darkBlue: "#181a35",
    darkBlueHover: "#2e3150",
    RoyalBlue: "#3e50b7",
    RoyalBlueHover: "#5c7cfa",
    pink: "#ce417c",
    pinkHover: "#e05a8e",
    pinkActive: "#b0365e",
  },
  box: {
    basic: "#262c4f",
    darkGray: "#262937",
    green: "#2ee89a",
    darkBox: "#191b25",
    disabled: "#5c617a",
  },
  tab: {
    noneSelectedTab: "#181a35",
  },
  input: {
    basic: "#181a35",
  },
  chart: {
    green: "#2ee89a",
    pink: "#ce417c",
    yellow: "#d7b027",
    blue: "#477AFF",
    sky: "#33d5eb",
    purple: "#9361ff",
    background: "#272937",
  },
  text: {
    info: "#9dadca",
    secondary: "#5c617a",
    error: "#ce417c",
    black: "#191b25",
    blue: "#467aff",
    green: "#2ee89a",
  },
  table: {
    border: "#262937",
  },
};

export default colors;
