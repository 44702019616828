import React from "react";
import { ContainerTitle, TextPink } from "../../css/dashboard/Dashboard.styles";

import {
  AnomalyDetectionBlank,
  AnomalyDetectionContainer,
  AnomalyDetectionLabel,
  AnomalyDetectionNaviboxTitle,
  AnomalyDetectionProgress,
  AnomalyDetectionProgressBox,
  AnomalyDetectionProgressContainer,
  AnomalyDetectionProgressText,
  AnomalyDetectionProgressWrapper,
  AnomalyDetectionStoreage,
  AnomalyDetectionTemp,
  AnomalyDetectionTempLabel,
} from "../../css/dashboard/AnomalyDetection.styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as IcStorage } from "../../asset/icons/ic_storage.svg";
import { ReactComponent as IcCPU } from "../../asset/icons/ic_CPU.svg";

const AnomalyDetectionStatus = ({ data }: { data: object }) => {
  const { t } = useTranslation();
  const warningCount = Object.keys(data).length;
  let warningCnt = { temp: 0, storage: 0 };
  const warnings = Object.entries(data).flatMap(([key, value]) => {
    return Object.entries(value).map(([innerKey, innerValue]) => {
      if (innerKey === "CPU" || innerKey === "GPU") warningCnt.temp++;
      if (innerKey === "Storage") warningCnt.storage++;

      return [key, { [innerKey]: innerValue }];
    });
  });
  return (
    <AnomalyDetectionContainer>
      <ContainerTitle>
        {t("ANOMALY_DETECTION_TITLE")}
        <TextPink>
          {" "}
          {warningCount} {t("NUM_UNIT")}
        </TextPink>
      </ContainerTitle>
      <AnomalyDetectionProgressContainer>
        <AnomalyDetectionTemp>
          <AnomalyDetectionTempLabel>
            {t("ANOMALY_DETECTION_TEMP")}
          </AnomalyDetectionTempLabel>
          {warningCnt.temp > 0 ? (
            warnings.map((warning, index) => {
              const [[key, value]] = Object.entries(warning[1]) as [
                [string, number],
              ];

              return (
                <>
                  {key !== "Storage" ? (
                    <AnomalyDetectionProgressBox key={index}>
                      <AnomalyDetectionNaviboxTitle>
                        {warning[0] + ""}
                      </AnomalyDetectionNaviboxTitle>
                      <AnomalyDetectionProgressWrapper>
                        <AnomalyDetectionProgress
                          $width={value + ""}
                        ></AnomalyDetectionProgress>
                        <AnomalyDetectionProgressText>
                          {Math.round(value * 100) / 100}°C
                        </AnomalyDetectionProgressText>
                      </AnomalyDetectionProgressWrapper>
                      <AnomalyDetectionLabel>{key}</AnomalyDetectionLabel>
                    </AnomalyDetectionProgressBox>
                  ) : null}
                </>
              );
            })
          ) : (
            <AnomalyDetectionBlank>
              <IcCPU />
              <p>{t("ANOMALY_DETECTION_BLANK")}</p>
            </AnomalyDetectionBlank>
          )}
        </AnomalyDetectionTemp>
        <AnomalyDetectionStoreage>
          <AnomalyDetectionTempLabel>
            {t("ANOMALY_DETECTION_STORAGE")}
          </AnomalyDetectionTempLabel>
          {warningCnt.storage > 0 ? (
            warnings.map((warning, index) => {
              const [[key, value]] = Object.entries(warning[1]) as [
                [string, number],
              ];

              return (
                <>
                  {key === "Storage" ? (
                    <AnomalyDetectionProgressBox key={index}>
                      <AnomalyDetectionNaviboxTitle>
                        {warning[0] + ""}
                      </AnomalyDetectionNaviboxTitle>
                      <AnomalyDetectionProgressWrapper>
                        <AnomalyDetectionProgress
                          $width={value + ""}
                        ></AnomalyDetectionProgress>
                        <AnomalyDetectionProgressText>
                          {Math.round(value * 100) / 100}%
                        </AnomalyDetectionProgressText>
                      </AnomalyDetectionProgressWrapper>
                    </AnomalyDetectionProgressBox>
                  ) : null}
                </>
              );
            })
          ) : (
            <AnomalyDetectionBlank>
              <IcStorage />
              <p>{t("ANOMALY_DETECTION_BLANK")}</p>
            </AnomalyDetectionBlank>
          )}
        </AnomalyDetectionStoreage>
      </AnomalyDetectionProgressContainer>
    </AnomalyDetectionContainer>
  );
};

export default AnomalyDetectionStatus;
