import React, { useEffect, useState } from "react";
import {
  ContainerTitle,
  TextBlue,
  TextGray,
  TextGreen,
} from "../../css/dashboard/Dashboard.styles";
import {
  AlgorithmChart,
  BoardChart,
  NaviboxOperationBox,
  NaviboxOperationContainer,
  NaviboxOperationDeviceName,
  NaviboxOperationStatus,
  NaviboxStatusChartBox,
  NaviboxStatusChartContainer,
  NaviboxStatusChartLabelWrapper,
  NaviboxStatusChartTitle,
  NaviboxStatusChartValue,
  NaviboxStatusChartValueWrapper,
  NaviboxStatusChartWrapper,
  NaviboxStatusContainer,
  OperationChart,
  RecordChart,
} from "../../css/dashboard/NaviboxStatus.styles";
import Radial from "../../util/chart/Radial";
import colors from "../../css/global/colors";
import { useTranslation } from "react-i18next";
import { initialNavibosData } from "../navibox/naviboxList/ChartBox";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../../api/DataApi";
import { FileStatusBold } from "../../css/dashboard/FileStatus.styles";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../util/Spinner";
import { SpinnerBox } from "../../css/util/Spinner.styles";

export interface INaviboxStatusProps {
  data: {
    algorithm: number;
    is_connected: number;
    panel: number;
    percentage_algorithm: number;
    percentage_operating: number;
    percentage_panel: number;
    percentage_record: number;
    record: number;
    total: number;
  };
}

export const initialNaviboxStatusData = {
  algorithm: 0,
  is_connected: 0,
  panel: 0,
  percentage_algorithm: 0,
  percentage_operating: 0,
  percentage_panel: 0,
  percentage_record: 0,
  record: 0,
  total: 0,
};

const NaviboxOperation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [naviboxes, setNaviboxes] = useState([initialNavibosData]);
  const [isConnected, setIsConnected] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["naviboxList"],
    queryFn: DataApi.naviboxList,
  });
  useEffect(() => {
    if (isSuccess) {
      setNaviboxes(data);
      const isConnectedNum = data.filter(
        (navibox: typeof initialNavibosData) => navibox.is_connected,
      ).length;
      setIsConnected(isConnectedNum);
      setTotalCount(data.length);
    }
  }, [data]);

  return (
    <NaviboxStatusContainer>
      <ContainerTitle>
        {t("NAVIBOX_STATUS_TITLE")}
        <TextGreen>
          {"  "}
          {isConnected}
          {t("NUM_UNIT")}
        </TextGreen>
        <TextGray>
          / {totalCount}
          {t("NUM_UNIT")}
        </TextGray>
      </ContainerTitle>
      <NaviboxOperationContainer $isLoading={isLoading}>
        {isLoading ? (
          <Spinner size={70} bgColor={"#191b25"} />
        ) : (
          data &&
          data.map((navibox: typeof initialNavibosData) => (
            <NaviboxOperationBox
              $active={Boolean(navibox.is_connected)}
              key={navibox.device_id}
              onClick={() =>
                navigate(`/navibox/${navibox.device_id}`, {
                  state: {
                    deviceName: navibox.device_name,
                    isConnected: Boolean(navibox.is_connected),
                  },
                })
              }
            >
              <NaviboxOperationDeviceName
                $active={Boolean(navibox.is_connected)}
              >
                {navibox.device_name}
              </NaviboxOperationDeviceName>
              <NaviboxOperationStatus $active={Boolean(navibox.is_connected)}>
                {navibox.is_connected
                  ? t("NAVIBOX_WORKING")
                  : t("NAVIBOX_NOTWORKING")}
              </NaviboxOperationStatus>
            </NaviboxOperationBox>
          ))
        )}
        )
      </NaviboxOperationContainer>
    </NaviboxStatusContainer>
  );
};

export default NaviboxOperation;
