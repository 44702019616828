import styled from "styled-components";
import colors from "../global/colors";

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0e0f16;
`;

export const LogoWrapper = styled.div`
  margin: 0 auto 100px;
`;

export const LoginBox = styled.form`
  width: 296px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 60px 50px;
  border-radius: 10px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  background-color: ${colors.box.basic};
`;

export const InputContainer = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  flex-direction: row;

  input {
    width: 100%;
    flex-grow: 0;
    margin: 0 0 10px;
    padding: 15px 100px 15px 55px;
    color: #fff;
    border: none;
    border-radius: 5px;
    background-color: ${colors.input.basic};

    &:focus {
      ${(props) =>
        props.id !== "password" &&
        `
        background-color: #141523;
        outline: none;

        &::-webkit-input-placeholder {
          color: #fff;
        }
      `}
    }
  }
`;

export const LoginIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SvgWrapper = styled.div`
  position: relative;
`;

export const Focus = styled.div`
  fill: red;

  &:hover {
    fill: white;
  }
`;

export const LoginButtonWrapper = styled.div`
  button {
    cursor: pointer;
    width: 296px;
    height: 50px;
    flex-grow: 0;
    margin: 40px 0 0;
    padding: 16px 0 15px 0;
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    background-color: ${colors.btn.primary};

    &:hover {
      background-color: ${colors.btn.primaryHover};
    }
    &:active {
      background-color: ${colors.btn.primaryActive};
    }
  }
`;

export const UserHelpWrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 40px 135px 0;
    font-family: SUIT;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.23px;
    text-align: center;
    color: ${colors.text.secondary};
    text-decoration: none;
  }
`;

export const LoginErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-family: SUIT;
  font-size: 15px;
  color: ${colors.text.error};
`;
