import styled from "styled-components";
import colors from "../global/colors";
import { FlexColumn } from "../common/Common.styles";

export const TimePickerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  z-index: 2;
`;

export const TimePickerBox = styled.div`
  position: absolute;
  top: 80px;
  left: 30px;
  width: 212px;
  height: 255px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 30px 20px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${colors.box.darkBox};
  z-index: 10;
`;
export const TimePickerTitle = styled.div`
  display: flex;
  justify-content: center;
  font-family: SUIT;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.text.secondary};
  margin-bottom: 10px;
`;

export const TimePickerItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimePickerContainer = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TimePickerWrapper = styled(FlexColumn)`
  position: relative;
  //overflow: hidden;
`;

export const TimePickerTime = styled.button<{ $active: boolean }>`
  width: 36px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 600;
  color: #d7d7d7;
  margin: 5px;
  cursor: pointer;
  background-color: ${({ $active }) =>
    $active ? colors.btn.primary : "transparent"};
  &:hover {
    background-color: ${({ $active }) =>
      $active ? colors.btn.primary : colors.btn.primaryHover};
  }
  &:active {
    background-color: ${colors.btn.primaryActive};
  }
`;
