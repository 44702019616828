import styled from "styled-components";
import { FlexRow } from "../../common/Common.styles";
import colors from "../../global/colors";

export const DetailHeader = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

export const DetailNameWrapper = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-family: SUIT;
  font-size: 26px;
  font-weight: 500;
  &lebel {
    color: ${colors.text.blue};
  }
  svg {
    margin-left: 12px;
    margin-top: 6px;
    fill: ${(props) => (props.$active ? "#467aff" : "#5c617a")};
  }
  color: ${(props) => (props.$active ? "#467aff" : "#5c617a")};
`;

export const CaptureContainer = styled(FlexRow)`
  justify-content: space-between;
`;

export const CameraSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 110px;
  height: 50px;
  justify-content: space-between;
  margin-right: 10px;
  font-size: 19px;
  font-weight: 600;
  color: #fff;
`;

export const CameraButton = styled.button<{
  $clicked: boolean;
  $active: boolean;
}>`
  width: 50px;
  height: 50px;
  color: white;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.$active ? "pointer" : "default")};
  background: ${(props) =>
    !props.$active
      ? "#3e4263"
      : props.$clicked
        ? colors.btn.lightBlueActive
        : colors.btn.ligthBlueUnactive};
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  &:hover {
    background: ${(props) =>
      !props.$active
        ? "#3e4263"
        : props.$clicked
          ? colors.btn.lightBlueActive
          : "#3e4263"};
  }
`;

export const CaptureButtonWrapper = styled.div<{
  $active: boolean;
}>`
  width: 114px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background: ${(props) =>
    !props.$active ? "#3e4263" : colors.btn.ligthBlueUnactive};
  cursor: ${(props) => (props.$active ? "pointer" : "default")};
  z-index: 10;
  svg {
    width: 27px;
    height: 24px;
  }
  &:hover {
    background: ${(props) =>
      !props.$active ? "#3e4263" : colors.btn.lightBlueActive};
  }
`;

export const CaptureButtonTitle = styled.label`
  font-family: SUIT;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
`;

export const CaptureFolderWrapper = styled.div<{
  $active: boolean;
}>`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background: ${(props) =>
    !props.$active ? "#3e4263" : colors.btn.ligthBlueUnactive};
  cursor: ${(props) => (props.$active ? "pointer" : "default")};
  svg {
    fill: #fff;
  }
  &:hover {
    background: ${(props) =>
      !props.$active ? "#3e4263" : colors.btn.lightBlueActive};
  }
`;

export const VideoWrapper = styled.div`
  margin-top: 20px;
  width: 1020px;
  height: 765px;
  video {
    width: 100%;
    height: 765px;
    //background-color: ${colors.box.darkGray};
  }
`;

export const DetailTabContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
`;
