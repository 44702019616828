import React, { useRef, useEffect } from "react";

import { CalendarProps } from "../../interface/Calendar";
import { SmCalendarWrapper } from "../../css/util/Calendar.styles";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../store/features/calendarSlice";

import Calendar from "./Calendar";
import { RootState } from "../../store/store";

const SmCalendar = ({ field, opener, type, eventDates }: CalendarProps) => {
  const dispatch = useDispatch();
  const calendarRef: React.RefObject<HTMLElement | null> = useRef(null);
  const calendarOpen = useSelector((state: RootState) => state.calendar.open);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const openerElement =
        opener && "current" in opener ? opener.current : null;

      if (!openerElement || !calendarRef.current) {
        return;
      }

      if (
        calendarOpen &&
        !calendarRef.current.contains(event.target as Node) &&
        !openerElement.contains(event.target as Node)
      ) {
        dispatch(setOpen(false));
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch]);

  return (
    <SmCalendarWrapper ref={calendarRef as React.RefObject<HTMLDivElement>}>
      <Calendar opener={opener} type={type} eventDates={eventDates} />
    </SmCalendarWrapper>
  );
};

export default SmCalendar;
