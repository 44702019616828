import React, { SetStateAction } from "react";
import {
  UserAccountScreenContainer,
  UserAccountScreenRow,
  UserAccountScreenWrapper,
} from "../../css/account/Account.styles";
import { FlexRow } from "../../css/common/Common.styles";
import { ReactComponent as Ic16 } from "../../asset/icons/ic_screen_a.svg";
import { ReactComponent as Ic9 } from "../../asset/icons/ic_screen_b.svg";
import { ReactComponent as Ic4 } from "../../asset/icons/ic_screen_c.svg";
import { ReactComponent as IcList } from "../../asset/icons/ic_screen_d.svg";
import { ReactComponent as IcDashboard } from "../../asset/icons/ic_screen_e.svg";
import { useTranslation } from "react-i18next";

const UserAccountScreen = ({
  selectedScreen,
  setSelectedScreen,
}: {
  selectedScreen: string;
  setSelectedScreen: React.Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();

  const handleClickScreen = (screen: string) => setSelectedScreen(screen);

  return (
    <UserAccountScreenContainer>
      <UserAccountScreenRow>
        {/*<UserAccountScreenWrapper*/}
        {/*  $active={selectedScreen === "division_16"}*/}
        {/*  onClick={() => handleClickScreen("division_16")}*/}
        {/*>*/}
        {/*  <label>{t("DIVISION_16")}</label>*/}
        {/*  <Ic16 />*/}
        {/*</UserAccountScreenWrapper>*/}
        {/*<UserAccountScreenWrapper*/}
        {/*  $active={selectedScreen === "division_9"}*/}
        {/*  onClick={() => handleClickScreen("division_9")}*/}
        {/*>*/}
        {/*  <label>{t("DIVISION_9")}</label>*/}
        {/*  <Ic9 />*/}
        {/*</UserAccountScreenWrapper>*/}
        {/*<UserAccountScreenWrapper*/}
        {/*  $active={selectedScreen === "division_4"}*/}
        {/*  onClick={() => handleClickScreen("division_4")}*/}
        {/*>*/}
        {/*  <label>{t("DIVISION_4")}</label>*/}
        {/*  <Ic4 />*/}
        {/*</UserAccountScreenWrapper>*/}
      </UserAccountScreenRow>
      <FlexRow style={{ gap: "20px" }}>
        <UserAccountScreenWrapper
          $active={selectedScreen === "list"}
          onClick={() => handleClickScreen("list")}
        >
          <label>{t("LIST_TYPE")}</label>
          <IcList />
        </UserAccountScreenWrapper>
        <UserAccountScreenWrapper
          $active={selectedScreen === "dashboard"}
          onClick={() => handleClickScreen("dashboard")}
        >
          <label>{t("DASHBOARD")}</label>
          <IcDashboard />
        </UserAccountScreenWrapper>
      </FlexRow>
    </UserAccountScreenContainer>
  );
};

export default UserAccountScreen;
