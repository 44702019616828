// 파일 상태 컨테이너 및 스타일
import styled from "styled-components";
import { dashboardBox } from "./Dashboard.styles";

export const FileStatusContainer = styled(dashboardBox)`
  width: 390px;
  //height: 210px;
  height: 300px;
`;

export const FileStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FileStatusNormal = styled.span`
  font-family: SUIT;
  font-size: 14px;
  font-weight: 500;
  color: #d7d7d7;
  margin-bottom: 10px;
`;

export const FileStatusBold = styled.span`
  font-family: Gilroy;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
`;

export const FileStatusRow = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: space-around;
  align-items: center;
  margin: 40px 30px 0 30px;
`;
