import React from "react";
import ApexChart from "react-apexcharts";
import colors from "../../css/global/colors";
import { RadialChartProps } from "../../interface/Chart";
const Radial = ({
  data,
  color,
  height,
  unit,
  fontsize,
  valueShow = true,
}: RadialChartProps) => {
  return (
    <ApexChart
      type="radialBar"
      series={data}
      height={height}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          offsetY: -20,
          sparkline: {
            enabled: false,
          },
          background: "transparent",
          fontFamily: "pretendard",
        },
        theme: {
          mode: "light",
        },
        plotOptions: {
          radialBar: {
            hollow: { size: "47%" },
            startAngle: -90,
            endAngle: 90,
            track: {
              margin: 0,
              background: colors.chart.background,
              strokeWidth: "100%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: valueShow,
                offsetY: -2,
                fontSize: fontsize ? fontsize : "26px",
                fontFamily: "Gilroy",
                fontWeight: 600,
                color: data[0] === 0 ? "#5c617a" : "#fff",
                formatter: function (val) {
                  let res = String(val);
                  if (!!unit) {
                    res = res + unit;
                  }
                  return res;
                },
              },
            },
          },
        },

        fill: {
          colors: [color],
          type: "solid",
        },
        grid: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltip: {
          y: { formatter: (value) => value + "℃" },
        },
      }}
    />
  );
};

export default Radial;
