import styled from "styled-components";
import { FlexRow } from "../../../common/Common.styles";
import colors from "../../../global/colors";

export const StatusCanvas = styled.div`
  display: block;
  box-sizing: border-box;
`;

export const StatusContent = styled(FlexRow)`
  width: 100%;
`;

const StatusContainer = styled.div`
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
`;
export const StatusTitleWrapper = styled.div`
  display: flex;
  label {
    font-family: "SUIT";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #d7d7d7;
  }
`;

export const StatusChartWrapper = styled.div`
  position: relative;
`;

export const StatusCanvasText = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusCanvasValue = styled.span`
  font-family: "Gilroy";
  font-weight: bold;
  color: #fff;
`;

export const StatusCanvasTitle = styled.span`
  font-family: "SUIT";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #d7d7d7;
`;

export const StatusTempContainer = styled(StatusContainer)`
  display: flex;
  flex-direction: column;
  width: 820px;
  height: 302px;
`;

export const StatusTempCanvas = styled(StatusCanvas)`
  height: 140px;
  width: 165px;
`;

export const StatusTempCanvasText = styled(StatusCanvasText)`
  top: 70px;
`;

export const StatusTempCanvasValue = styled(StatusCanvasValue)`
  font-size: 18px;
`;

export const StatusTempCanvasTitle = styled(StatusCanvasTitle)`
  font-size: 12px;
`;

export const StatusTempRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  flex-wrap: wrap;
`;

export const StatusGPUCanvas = styled(StatusCanvas)`
  height: 140px;
  width: 220px;
`;

export const StatusGPUText = styled(StatusCanvasText)`
  top: 110px;
`;

export const StatusGPUValue = styled(StatusCanvasValue)`
  font-size: 32px;
`;
export const StatusGPUTitle = styled(StatusCanvasTitle)`
  margin-top: 20px;
  font-size: 14px;
`;

export const StatusGPUContainer = styled(StatusContainer)`
  width: 375px;
  height: 210px;
  margin-right: 10px;
  margin-top: 10px;
`;

export const StatusGPUWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StatusStorageContainer = styled(StatusContainer)`
  width: 375px;
  height: 70px;
  margin-top: 10px;
  border-radius: 10px;
`;

export const StatusProgressFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusProgressValueWrapper = styled.div`
  width: 59px;
  text-align: left;
  font-family: "Gilroy";
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
`;

export const StatusProgressBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
`;

export const StatusProgressWrapper = styled.div`
  position: relative;
  width: 220px;
  height: 30px;
  border-radius: 15px;
  background-color: #262937;
`;

export const StatusProgress = styled.div<{ value: number; $bgcolor: string }>`
  width: ${(props) => props.value}%;
  height: 30px;
  border-radius: 15px;
  background-color: ${(props) => props.$bgcolor};
`;

export const StatusCPUContainer = styled(StatusContainer)`
  width: 410px;
  height: 582px;
  margin-left: 10px;
`;

export const StatusCPUGrid = styled(FlexRow)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  place-items: center;
  padding: 10px 20px;
  gap: 20px;
`;

export const StatusCPUCanvas = styled(StatusCanvas)`
  height: 140px;
  width: 160px;
`;
export const StatusCPUText = styled(StatusCanvasText)`
  top: 70px;
`;

export const StatusCPUValue = styled(StatusCanvasValue)`
  font-size: 18px;
`;
export const StatusCPUTitle = styled(StatusCanvasTitle)`
  font-size: 12px;
`;

export const DisabledStatus = styled.span`
  color: #5c617a !important;
`;
