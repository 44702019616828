import styled from "styled-components";
import { Link } from "react-router-dom";
import "../../asset/fonts/font.css";
export const Header = styled.header`
  width: 100%;
  height: 80px;
  background-color: #191b25;
  display: flex;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLogoWrapper = styled.div`
  margin-left: 40px;
  cursor: pointer;
`;

export const HeaderMenuContainer = styled.nav`
  ul {
    display: flex;
    flex-direction: row;
  }

  li {
    margin: 0 40px;
  }

  a {
    font-family: SUIT;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const HeaderRightContainer = styled.div`
  margin-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LanguageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 38px;
`;

export const HeaderLanguage = styled.span<{ $active: boolean }>`
  font-family: Gilroy;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => (props.$active ? "#fff" : "#5c617a")};
  cursor: pointer;

  &:active {
    color: #fff;
  }
`;

export const Dot = styled.span`
  width: 2px;
  height: 2px;
  flex-grow: 0;
  margin: 4px 10px;
  background-color: #5c617a;
`;

export const HeaderLogoutWrapper = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  .header-logout {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
`;

export const MenuName = styled(Link)<{ $active: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.$active ? "white" : "#5c617a")};
`;

export const ContnetWarpper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
