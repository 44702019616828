import React, { useEffect, useState } from "react";
import {
  NaviboxBox,
  NaviboxContainer,
  NaviboxNameWrapper,
  NaviboxStatusChartContainer,
  NaviboxStatusContainer,
  NaviboxVideo,
} from "../css/navibox/naviboxList/NaviboxList.styles";
import { ReactComponent as IcPower } from "../asset/icons/ic_power.svg";

import { FlexRow } from "../css/common/Common.styles";
import ChartBox, {
  initialNavibosData,
} from "../content/navibox/naviboxList/ChartBox";
import StorageProgressBox from "../content/navibox/naviboxList/StorageProgressBox";
import CaptureButtons from "../content/navibox/naviboxList/CaptureButtons";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DataApi } from "../api/DataApi";
import NaviboxStreamPreview from "../content/navibox/naviboxList/NaviboxStreamPreview";
import Spinner from "../util/Spinner";
import { SpinnerWrapper } from "../css/util/Spinner.styles";

export interface IIsPending {
  deviceName: string;
  status: boolean;
}

const Navibox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [naviboxes, setNaviboxes] = useState([initialNavibosData]);
  const [isPending, setIsPending] = useState({ deviceName: "", status: false });
  const { data, isSuccess } = useQuery({
    queryKey: ["naviboxList"],
    queryFn: DataApi.naviboxList,
  });
  useEffect(() => {
    if (isSuccess) {
      setNaviboxes(data);
    }
  }, [data]);
  return (
    <NaviboxContainer>
      {isSuccess && naviboxes ? (
        naviboxes.map((navibox, index) => (
          <NaviboxBox
            key={index}
            onClick={() =>
              navigate(location.pathname + `/${navibox.device_id}`, {
                state: {
                  deviceName: navibox.device_name,
                  isConnected: Boolean(navibox.is_connected),
                },
              })
            }
            $active={Boolean(navibox.is_connected)}
          >
            {navibox.is_connected ? (
              isPending.deviceName === navibox.device_name &&
              isPending.status ? (
                <NaviboxVideo>
                  <Spinner bgColor={"black"} />
                </NaviboxVideo>
              ) : (
                <NaviboxVideo>
                  <NaviboxStreamPreview
                    is_connected={Boolean(navibox.is_connected)}
                    deviceName={navibox.device_name}
                  />
                </NaviboxVideo>
              )
            ) : (
              <NaviboxVideo></NaviboxVideo>
            )}

            <NaviboxStatusContainer>
              <NaviboxNameWrapper $active={Boolean(navibox.is_connected)}>
                {navibox.device_name}
                <IcPower />
              </NaviboxNameWrapper>
              <NaviboxStatusChartContainer>
                <FlexRow>
                  <FlexRow>
                    <ChartBox
                      value={
                        navibox.is_connected
                          ? Math.round(Number(navibox.cpu_temperature) * 10) /
                            10
                          : 0
                      }
                      title={t("CPU_TITLE")}
                      $active={Boolean(navibox.is_connected)}
                    />
                    <ChartBox
                      value={
                        navibox.is_connected
                          ? Math.round(Number(navibox.gpu_temperature) * 10) /
                            10
                          : 0
                      }
                      title={t("GPU_TITLE")}
                      $active={Boolean(navibox.is_connected)}
                    />
                  </FlexRow>
                </FlexRow>
                <div>
                  <StorageProgressBox
                    value={
                      navibox.is_connected ? Number(navibox.storage_usage) : 0
                    }
                    title={t("STORAGE_TITLE")}
                    $active={Boolean(navibox.is_connected)}
                  />
                  <StorageProgressBox
                    value={navibox.is_connected ? Number(navibox.ram_usage) : 0}
                    title={t("RAM_TITLE")}
                    $active={Boolean(navibox.is_connected)}
                  />
                </div>
                <CaptureButtons
                  $active={Boolean(navibox.is_connected)}
                  device_name={navibox.device_name}
                  setIsPending={setIsPending}
                  isPending={isPending}
                  disabled={
                    isPending.deviceName === navibox.device_name &&
                    isPending.status
                  }
                ></CaptureButtons>
              </NaviboxStatusChartContainer>
            </NaviboxStatusContainer>
          </NaviboxBox>
        ))
      ) : (
        <SpinnerWrapper>
          <Spinner size={70} bgColor={"#0e1016"} />
        </SpinnerWrapper>
      )}
    </NaviboxContainer>
  );
};

export default Navibox;
