import styled from "styled-components";
import { dashboardBox } from "./Dashboard.styles";
import { FlexRow } from "../common/Common.styles";

export const TrafficContainer = styled(dashboardBox)`
  width: 840px;
  height: 227px;
  margin-top: 10px;
  margin-left: 10px;
`;

export const TrafficChartContainer = styled(FlexRow)`
  position: relative;
  margin-top: 20px;
`;

export const TrafficChartWrapper = styled.div`
  position: absolute;
  width: 50%;
  top: -15px;
`;

export const VolumeChart = styled(TrafficChartWrapper)`
  left: -10px;
`;
export const SpeedChart = styled(TrafficChartWrapper)`
  right: -10px;
`;

export const TrafficChart = styled.div`
  display: flex;
`;

export const TrafficChartTitle = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  label {
    font-family: SUIT;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #d7d7d7;
  }
`;

export const TrafficRiskChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
