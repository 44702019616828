import React from "react";
import {
  ModalBackground,
  ModalClose,
  ModalContainer,
} from "../css/util/Modal.styles";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../store/features/modalSlice";
import PasswordModal from "../content/account/PasswordModal";
import ResignModal from "../content/account/ResignModal";

const Modal = ({ type }: { type: "password" | "resign" }) => {
  const dispatch = useDispatch();

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      dispatch(setModalOpen(false));
    }
  };

  return (
    <>
      <ModalBackground onClick={(e) => handleBackgroundClick(e)}>
        <ModalContainer>
          <ModalClose onClick={() => dispatch(setModalOpen(false))}>
            X
          </ModalClose>
          {type === "password" && <PasswordModal />}
          {type === "resign" && <ResignModal />}
        </ModalContainer>
      </ModalBackground>
    </>
  );
};

export default Modal;
