import styled, { css } from "styled-components";
import colors from "../../../global/colors";
import { BlueBtn } from "../../../common/Common.styles";

export const DeviceInfoContainer = styled.div`
  position: relative;
  width: 1280px;
  height: 860px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
`;

export const DeviceInfoTable = styled.table`
  width: 100%;
  font-family: SUIT;
  border-top: 1px solid ${colors.table.border};
`;

export const DeviceInfoTableTr = styled.tr``;

export const DeviceInfoTitleTd = styled.td`
  padding: 0 20px;
  height: 69px;
  width: 130px;
  font-size: 15px;
  color: ${colors.text.secondary};
  border-bottom: 1px solid ${colors.table.border};
`;
export const DeviceInfoValueTd = styled.td`
  font-size: 15px;
  font-weight: 500;
  color: #d7d7d7;
  margin-left: 70px;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.table.border};
`;

export const DeviceInfoNameInput = styled.div`
  width: 900px;
  height: 50px;
  padding: 0 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #262937;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  color: #d7d7d7;
`;

export const DeviceInfoNameBtn = styled(BlueBtn)<{ disabled: boolean }>`
  width: 90px;
  height: 50px;
  margin-left: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")} !important;
  background-color: ${(props) =>
    props.disabled ? colors.btn.secondary : colors.btn.primary};
  &:hover {
    background-color: ${(props) =>
      props.disabled ? colors.btn.secondary : colors.btn.primaryHover};
  }
  &:active {
    background-color: ${(props) =>
      props.disabled ? colors.btn.secondary : colors.btn.primaryActive};
  }
`;

export const ToastWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  top: -60px;
  right: 0;
  padding: 15px 20px;
  overflow: hidden;
  visibility: hidden;
  transition:
    opacity 0.5s,
    visibility 0.5s,
    transform 0.5s;
  color: #fff;
  z-index: 10000;
  border-radius: 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #262c4f;

  ${(props) =>
    props.show &&
    css`
      visibility: visible !important;
    `}
`;

export const ToastMessage = styled.div`
  font-family: SUIT;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #2ee89a;
`;
