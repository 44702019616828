import styled from "styled-components";
import { BlueBtn, FlexColumn, FlexRow } from "../../../common/Common.styles";
import colors from "../../../global/colors";

export const VideoContainer = styled(FlexRow)`
  height: 628px;
  position: relative;
  justify-content: space-between;
  padding: 40px;
  border-radius: 10px;

  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.box.darkBox};
`;

export const VideoModalBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: hidden;
`;

export const VideoBox = styled(FlexColumn)`
  width: 800px;
  position: relative;
`;

export const VideoWrapper = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  width: 800px;
`;

export const VideoContainerDivision = styled.div`
  width: 1360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
`;

export const FlexRowDivision = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Video4 = styled.div`
  video {
    width: 670px;
  }
`;
export const Video9 = styled.div`
  video {
    width: 440px;
  }
`;
export const Video16 = styled.div`
  video {
    width: 330px;
  }
`;

export const VideoInfoContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 30px;
  display: flex;
  flex-direction: row;
`;

export const VideoTrafficWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VideoTrafficTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const VideoTrafficTitle = styled.span`
  font-family: SUIT;
  font-size: 16px;
  color: #5c617a;
`;

export const VideoTrafficValue = styled.span`
  margin-left: 20px;
  font-family: SUIT;
  font-size: 16px;
  font-weight: 500;
  color: #d7d7d7;
`;

export const VideoBar = styled.div`
  width: 1px;
  height: 50px;
  flex-grow: 0;
  margin: 10px 39px 0 40px;
  background-color: #262937;
`;

export const VideoListContainer = styled(FlexColumn)`
  position: relative;
  width: 400px;
  height: 628px;
  border-radius: 10px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: #1f212c;
`;

export const VideoDateContainer = styled(FlexRow)`
  justify-content: center;
  padding: 20px;
  background-color: ${colors.box.darkGray};
  border-radius: 10px 10px 0 0;
`;

export const VideoDateWrapper = styled(FlexRow)`
  position: relative;
  width: 220px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.box.darkBox};
  cursor: pointer;
  svg {
    margin: 0 20px 0 24px;
  }
  label {
    flex: 1;
    font-family: SUIT;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.23px;
    text-align: left;
    color: #fff;
    cursor: pointer;
  }
`;

export const VideoDate = styled.div`
  flex: 1;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.23px;
  text-align: left;
  color: #fff;
`;

export const VideoDateSearch = styled(BlueBtn)`
  width: 90px;
  height: 50px;
  flex-grow: 0;
  margin-left: 10px;
`;

export const VideoListScroll = styled(FlexColumn)`
  width: 380px;
  padding: 0 10px;
  overflow: hidden;
`;

export const VideoListBox = styled(FlexColumn)`
  width: 320px;
  padding: 20px 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #1f212c;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 6px;
    background-color: #5c617a;
  }

  &::-webkit-scrollbar-track {
    background-color: #1f212c;
  }
`;

export const VideoBlankWrapper = styled(FlexColumn)`
  height: 500px;
  justify-content: center;
  align-items: center;
  font-family: SUIT;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.text.secondary};
  svg {
    width: 100px;
    height: 100px;
  }
  label {
    margin-top: 40px;
  }
`;

export const VideoItem = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 10px;
  font-family: SUIT;
  font-size: 16px;
  color: ${(props) => (props.$active ? "#fff" : "#5c617a")};
  div {
    transition-duration: 10ms !important;
  }
`;

export const VideoPoster = styled.div<{ $active: boolean }>`
  width: 160px;
  height: 90px;
  background: black;
  border: ${(props) => (props.$active ? "solid 2px #2ee89a" : "none")};
`;
export const ImagePoster = styled.img<{ $active: boolean }>`
  width: 160px;
  height: 90px;
  background: black;
  border: ${(props) => (props.$active ? "solid 2px #2ee89a" : "none")};
`;

export const VideoTextWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: 22px;
  margin-top: 10px;
  cursor: pointer;
  align-items: center;
`;

export const VideoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 11px;
`;
export const VideoTextDate = styled.div`
  cursor: pointer;
`;

export const VideoTextTime = styled.div`
  font-weight: 600;
  margin-top: 5px;
  cursor: pointer;
`;

export const VideoActive = styled.div`
  color: #fff !important;
`;

export const VideoCalendarContainer = styled.div`
  width: 268px;
  height: 295px;
  position: absolute;
  left: 0;
  top: 60px;
  padding: 30px 24px;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
  background-color: ${colors.box.darkBox};
  z-index: 2;
  visibility: hidden;
`;

export const ViedoDateSearchBtn = styled(BlueBtn)`
  width: 90px;
  height: 50px;
  flex-grow: 0;
  margin-left: 10px;
`;
